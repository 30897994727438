import styles from './data.module.css'
import React, { useState } from 'react'
import { IoIosAddCircleOutline } from 'react-icons/io'
import { Company, note2 } from 'types'
import {generateNewId} from 'functions/id'
import Note from './components/Note'

interface IData2{
  currentCompany:Company;
  DB:Array<Company>;
  setDB:(DB:Array<Company>)=>void;
  DB_old:Array<Company>;
}

const Data2:React.FC<IData2>=({currentCompany,DB,setDB,DB_old})=>{
  const staticCurrentCompany = DB_old.find(
    (company) => company._id === currentCompany._id
  )






  const handleDeleteNote = async (note:note2) => {
      const isConfirmed = window.confirm(
        `Are you sure you want to delete note ${note.title}?`
      )
      if (isConfirmed) {
        if(note.new){
          currentCompany.notes=currentCompany.notes.filter(n=>n._id!==note._id)
        }
        else{
          note.deleted=true
        }
        setDB([...DB])
      }
  }


  

  const handleAddNote = (parentNote?:note2) => {
        try {
          const newID = generateNewId(currentCompany.notes.map(n=>n._id)).toString()
          const newNote:note2={
            title: '',
            text: '',
            isProduction: false,
            _id: newID,
            url: '',
            new: true,
            useLocation:false,
            isHeadline:false,
            notes:[],
            type:"manual"
          }
          if(parentNote){
            newNote.parentId=parentNote._id
            parentNote.notes.unshift(newNote)
          }
          else{
            currentCompany.notes.unshift(newNote)
          }
          setDB([...DB])
        } catch (e) {
          console.log(e)
        }
      
    }
  

    const refreshDB=()=>{
      setDB([...DB])
    }




  console.log(currentCompany.notes)
  if (currentCompany) {
    return (
      <div className={styles.container}>
            <IoIosAddCircleOutline
                onClick={() => handleAddNote()}
                className={styles.addNoteButton}
              />

            {currentCompany &&
              currentCompany.notes
                .filter(note=> !note.deleted)
                .map((note, i) => {
                  if(staticCurrentCompany){
                    return(
                      <Note key={i} note={note} currentCompany={currentCompany} staticCurrentCompany={staticCurrentCompany} refreshDB={refreshDB} handleAddNote={handleAddNote}
                      />
                    )
                  }
                })}
      </div>
    )
  }
}

export default Data2

import styles from './URLBox.module.css'
import React from 'react'

const URLBox = ({ n, index, urls, setUrls }) => {
  const handleUrlChange = (i) => (e) => {
    urls[i].url = e.target.value
    setUrls([...urls])
  }
  const handleMultipleChange = (i) => (e) => {
    urls[i].multiple = e.target.checked
    setUrls([...urls])
  }
  const handleSubPageChange = (i) => (e) => {
    urls[i].subPage = e.target.value
    setUrls([...urls])
  }

  const handleStartNumberChange = (i) => (e) => {
    urls[i].startNumber = e.target.value
    setUrls([...urls])
  }

  const handleDeleteUrl = (i) => {
    urls = urls.filter((_, index) => i !== index)
    setUrls([...urls])
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '5px',
        paddingBottom: '15px',
      }}>
      <div style={{ width: '100%', display: 'flex', justifyContent: 'end' }}>
        <button onClick={() => handleDeleteUrl(index)}>x</button>
      </div>
      <div style={{ display: 'flex', flexDirection: 'row', gap: '5px' }}>
        <div>URL:</div>
        <input
          className={styles.urlInput}
          value={n.url}
          onChange={handleUrlChange(index)}
        />
      </div>

      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <div>set multiple pages</div>
        <input
          type='checkbox'
          checked={n.multiple}
          onChange={handleMultipleChange(index)}
        />
        {n.multiple && (
          <div style={{ display: 'flex', flexDirection: 'row', gap: '5px' }}>
            <div>subpage: </div>
            <input
              placeholder='/page/$'
              onChange={handleSubPageChange(index)}
              value={n.subPage}
            />
            <div>start number: </div>
            <input
              onChange={handleStartNumberChange(index)}
              value={n.startNumber}
              style={{ width: '40px' }}
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default URLBox

import React, { useState, useEffect } from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import Header from './components/Header'
import Dashboard from './pages/Dashboard'
import Test from './pages/Test'
import Save from './components/Save'
import Automation from './pages/Automation'
import Settings from './pages/Settings'
import Context from './pages/Context'
import Logic from './pages/Logic'
import Data from './pages/Data'
import { getAllCompanyNames,getDemoList,getPromptObj,getUrlObjs } from './api'
import SideBar from './components/SideBar'
import PopUp from './components/PopUp'
import addCompany from './functions/addCompany'
import SaveButton from './components/SaveButton'
import Prompts from 'pages/Prompts'
import Data2 from 'pages/Data2'
import Automation2 from 'pages/Automation2'
import AGI from 'pages/AGI'

const EmericRouter = () => {
  const [DB, setDB] = useState([])
  const [DB_old, setDB_old] = useState([])
  const [companyNames, setCompanyNames] = useState([])
  const [currentHeadLine, setCurrentHeadline] = useState('')

  const [companyName, setCompanyName] = useState('-')
  const [dataFound, setDataFound] = useState(false)
  const [newCompanyPopUp, setNewCompanyPopUp] = useState(false)
  const [demoList,setDemoList]=useState([])
  const [promptObject,setPromptObject]=useState({name:"",first:"",second:"",last:"",scrapeSelectors:"",linkFilter:"",scrapePrompt:""})
  const [promptObject_old,setPromptObject_old]=useState({name:"",first:"",second:"",last:"",scrapeSelectors:"",linkFilter:"",scrapePrompt:""})
  const splitted = window.location.pathname.split('/')

  if (DB.length === 0 && splitted[1] === 'company') {
    window.location.href = '/'
  }

  function renderDBs() {
    setDB([...DB])
    setDB_old([...DB_old])
  }

  let variable = false

  useEffect(()=>{
    if(window.location.pathname.split("/")[1]!=="chat" ){
        document.body.style.marginLeft="100px"
    }
  },[])

  useEffect(() => {
    async function fetchData() {
      try {
        if (window.location.pathname.split('/')[1] !== 'chat') {
          const response = await getAllCompanyNames()
          const myDemoList=await getDemoList()
          //const promptObj=await getPromptObj()
          //setPromptObject(promptObj.data)
          //setPromptObject_old(JSON.parse(JSON.stringify(promptObj.data)))
          setDemoList(myDemoList.data)
          setCompanyNames(response.data)
          setDataFound(true)
        }
      } catch (error) {
        console.error('Error:', error)
      }
    }

      fetchData()
  }, [variable])

  const currentCompany = DB.find((company) => company.name === companyName)
  console.log(currentCompany)

  return (
    <Router>
      {dataFound && (
        <div>
          <Header DB={DB} DB_old={DB_old} currentCompany={currentCompany} />
          <SideBar
            DB={DB}
            setDB={setDB}
            DB_old={DB_old}
            setDB_old={setDB_old}
            setCurrentHeadline={setCurrentHeadline}
            setCompanyName={setCompanyName}
            companyNames={companyNames}
            companyName={companyName}
            setNewCompanyPopUp={setNewCompanyPopUp}
          />
          <SaveButton
            currentCompany={currentCompany}
            DB={DB}
            DB_old={DB_old}
            renderDBs={renderDBs}
            promptObject_old={promptObject_old}
            promptObject={promptObject}
            setPromptObject_old={setPromptObject_old}

          />
        </div>
      )}

      {newCompanyPopUp && (
        <PopUp
          text={'New company'}
          setPopUp={setNewCompanyPopUp}
          save={(inputValue) =>
            addCompany({
              newCompanyName: inputValue,
              setCompanyNames,
              setNewCompanyPopUp,
            })
          }
        />
      )}

      <Routes>
        <Route exact path='/' element={<div> </div>} />

        <Route path='/company/:companyName' element={<div> </div>} />

        <Route path="/global" element={<Prompts promptObject={promptObject} setPromptObject={setPromptObject} />}/>

        <Route
          path='/company/:companyName/automation'
          element={
            <Automation
              setDB_old={setDB_old}
              currentCompany={currentCompany}
              setDB={setDB}
              DB={DB}
              DB_old={DB_old}
            />
          }
        />
                <Route
          path='/company/:companyName/automation2'
          element={
            <Automation2
            currentCompany={currentCompany}
              setDB={setDB}
              DB={DB}
            />
          }
        />
                <Route
          path='/company/:companyName/AGI'
          element={
            <AGI />
          }
        />


        <Route
          path='/company/:companyName/context'
          element={
            <Context
              currentCompany={currentCompany}
              DB={DB}
              setDB={setDB}
              DB_old={DB_old}
              setDB_old={setDB_old}
            />
          }
        />

        <Route
          path='/company/:companyName/Dashboard'
          element={<Dashboard currentCompany={currentCompany} />}
        />

        <Route
          path='/company/:companyName/test'
          element={
            <Test DB={DB} setDB={setDB} currentCompany={currentCompany} />
          }
        />

        <Route
          path='/company/:companyName/save'
          element={<Save DB={DB} DB_old={DB_old} />}
        />

        <Route
          path='/company/:companyName/settings'
          element={
            <Settings
              DB={DB}
              setDB={setDB}
              DB_old={DB_old}
              setDB_old={setDB_old}
              currentCompany={currentCompany}
              setCompanyName={setCompanyName}
              companyNames={companyNames}
              setCompanyNames={setCompanyNames}
              demoList={demoList}
              setDemoList={setDemoList}
            />
          }
        />

        <Route
          path='/company/:companyName/logic'
          element={
            <Logic DB={DB} currentCompany={currentCompany} setDB={setDB} />
          }
        />

        <Route
          path='/company/:companyName/data'
          element={
            <Data
              currentCompany={currentCompany}
              currentHeadLine={currentHeadLine}
              setCurrentHeadline={setCurrentHeadline}
              DB={DB}
              setDB={setDB}
              DB_old={DB_old}
              setDB_old={setDB_old}
            />
          }
        />
        <Route
          path='/company/:companyName/data2'
          element={
            <Data2
              currentCompany={currentCompany}
              currentHeadLine={currentHeadLine}
              setCurrentHeadline={setCurrentHeadline}
              DB={DB}
              setDB={setDB}
              DB_old={DB_old}
              setDB_old={setDB_old}
            />
          }
        />



      </Routes>
    </Router>
  )
}

export default EmericRouter

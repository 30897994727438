import MyChart from '../MyChart'
import { useState } from 'react'
import styles from './ChartHolder.module.css'

function isSameDay(date1, date2) {
  return (
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate()
  )
}

function chart1Function(chats, limit) {
  let thisDay = new Date()
  let k = []

  for (let i = 0; i < limit; i++) {
    k[i] = {
      date: `${thisDay.getDate()}-${thisDay.getMonth() + 1}`,
      chats: chats.filter((n) =>
        isSameDay(thisDay, new Date(n.id.split('T')[0]))
      ).length,
    }
    thisDay = new Date(thisDay - 86400000)
  }
  return k.reverse()
}

function chart2Function(chats, limit) {
  let thisDay = new Date()
  let k = []
  for (let i = 0; i < limit; i++) {
    k[i] = {
      date: `${thisDay.getDate()}-${thisDay.getMonth() + 1}`,
      chats: chats
        .filter((n) => isSameDay(thisDay, new Date(n.id.split('T')[0])))
        .map((n) => n.chats)
        .flat().length,
    }
    thisDay = new Date(thisDay - 86400000)
  }
  return k.reverse()
}

const ChartHolder = ({ chats }) => {
  const [selectedValue, setSelectedValue] = useState('7')
  const handleSelectChange = (event) => {
    setSelectedValue(event.target.value)
  }

  return (
    <div className={styles.chartHolder}>
      <select value={selectedValue} onChange={handleSelectChange}>
        <option value='7'>7 päivää</option>
        <option value='14'>14 päivää</option>
        <option value='31'>1 kuukausi</option>
        <option value='62'>2 kuukautta</option>
      </select>
      <MyChart
        label={'Chatit'}
        chats={chart1Function(chats, Number(selectedValue))}
      />
      <MyChart
        label={'Viestit'}
        chats={chart2Function(chats, Number(selectedValue))}
      />
    </div>
  )
}

export default ChartHolder

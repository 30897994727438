import React, { useEffect, useRef } from 'react'
import styles from './AutoExpandingTextarea.module.css'

const AutoExpandingTextarea = ({ myText, change, redBorder }) => {
  const textareaRef = useRef(null)

  const autoResize = () => {
    const textarea = textareaRef.current
    textarea.style.height = 'auto'
    textarea.style.height = `${textarea.scrollHeight+15}px`
  }

  useEffect(() => {
    autoResize()
  }, [myText])

  return (
    <textarea
      ref={textareaRef}
      onInput={autoResize}
      onChange={change}
      value={myText}
      style={
        redBorder ? { resize: 'none', borderColor: 'red' } : { resize: 'none' }
      }
      className={styles.textarea}
    />
  )
}
export default AutoExpandingTextarea

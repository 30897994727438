/* eslint-disable react/prop-types */
import styles from './SideBar.module.css'
import { getCompanyShorts } from '../../api'
import { useNavigate } from 'react-router'
import React, { useState } from 'react'
import getOneCompanyCount from '../../functions/getOneCompanyCount'
import { IoIosAddCircleOutline } from "react-icons/io";


const SideBar = ({
  DB,
  setDB,
  DB_old,
  setDB_old,
  setCurrentHeadline,
  setCompanyName,
  companyNames,
  companyName,
  setNewCompanyPopUp,
}) => {
  const navigate = useNavigate()
  let navigating = false
  const [hover, setHover] = useState('')
  const [companySearch, setCompanySearch] = useState('')

  const handleCompanySearch = (e) => {
    setCompanySearch(e.target.value.toLowerCase())
  }


  const handleNavigate = async (name) => {
    if (!navigating) {
      navigating = true
      setCurrentHeadline('')
      navigate(`/`)
      let companyFromDb = DB.find((n) => n.name === name)
      if (!companyFromDb) {
        let res = await getCompanyShorts(name)
        let companyData = res.data
        if (companyData) {
          companyData.notes2=[]
          DB.push(companyData)
          setDB([...DB])
          setDB_old((prev) =>
            prev.concat([JSON.parse(JSON.stringify(companyData))])
          )
          setCurrentHeadline(companyData.headlines[0] || "")
          setCompanyName(name)


          navigate(`/company/${name}/data`)
        }
      } else {
        setCompanyName(name)
        setCurrentHeadline(companyFromDb.headlines[0])
        navigate(`/company/${name}/data`)
      }
      navigating = false
    }
  }

  const myStyles = {}
  if (window.location.pathname.split('/')[1] === 'chat') {
    myStyles.display = 'none'
  }
  if (hover) {
    myStyles.width = '200px'
  } else {
    myStyles.width = '100px'
  }

  return (

    <div
      className={styles.container}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      style={myStyles}>
    
    <div className={styles.mainSettings} onClick={()=>navigate(`/global`)}>Globals</div>


      <div className={styles.inputContainer}>
        <input
          className={styles.companySearch}
          value={companySearch}
          onChange={handleCompanySearch}
        />
      </div>

      {
      companyNames.map((company,i)=>{
          const count=getOneCompanyCount(company.name,DB,DB_old).length
          return (
            <div
              className={styles.company}
              key={i}
              onClick={() => handleNavigate(company.name)}
              style={
                companyName === company.name
                  ? { backgroundColor: 'white', color: 'black' }
                  : {}
              }>
              <span>{company.name}</span>
              {count>0 && <span className={styles.redCount}>{count}</span>}
            </div>
          )
        

      })
    }

      <div className={styles.buttonContainer}>
        <IoIosAddCircleOutline onClick={() => setNewCompanyPopUp((prev) => !prev)}/>
      </div>
    </div>
  )
}

export default SideBar

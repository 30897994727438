import { useState } from 'react'
import styles from './Propmts.module.css'
import { promptObject } from 'types'
import AutoExpandingTextarea from 'components/AutoExpandingTextarea'
interface IPrompts{
    promptObject:promptObject|null;
    setPromptObject:(promptObject:promptObject)=>void;
}

const Prompts:React.FC<IPrompts>=({promptObject,setPromptObject})=>{
    const [optionals,setOptionals]=useState([])

    const handleFirstChange=(text:string)=>{
        console.log(promptObject)
        if(promptObject){
            promptObject.first=text
            setPromptObject({...promptObject})
        }
    }
    const handleSecondChange=(text:string)=>{
        if(promptObject){
            promptObject.second=text
            setPromptObject({...promptObject})
        }
    }
    const handleLastChange=(text:string)=>{
        if(promptObject){
            promptObject.last=text
            setPromptObject({...promptObject})
        }
    }
    const handleScrapePromptChange=(text:string)=>{
        if(promptObject){
            promptObject.scrapePrompt=text
            setPromptObject({...promptObject})
        }
    }
    const handleScrapeSelectorsChange=(text:string)=>{
        if(promptObject){
            promptObject.scrapeSelctors=text
            setPromptObject({...promptObject})
        }
    }

    const handleLinkFilterChange=(text:string)=>{
        if(promptObject){
            promptObject.linkFilter=text
            setPromptObject({...promptObject})
        }
    }


    if(promptObject){
        console.log(promptObject)
        return(
            <div className={styles.container}>
                <div className={styles.box}>
                    <div>
                        <div>First prompt</div> 
                        <div>{"Include {chats} and {titles}"}</div>
                        <AutoExpandingTextarea myValue={promptObject.first} setValue={handleFirstChange}/>
                    </div>

                    <div>
                        <div>Second prompt</div> 
                        <div>{"Include {chats} and {titles}"}</div>
                        <AutoExpandingTextarea myValue={promptObject.second} setValue={handleSecondChange}/>
                    </div>

                    <div>
                        <div>Last prompt</div> 
                        <div>{"Include {chats} and {data}"}</div>
                        <AutoExpandingTextarea myValue={promptObject.last} setValue={handleLastChange}/>
                    </div>
                </div>
                <div className={styles.box}>
                    <div>
                        <div>Scrape prompt</div> 
                        <div>{"Include {textcontent}"}</div>
                        <AutoExpandingTextarea myValue={promptObject.scrapePrompt} setValue={handleScrapePromptChange}/>
                    </div>
                    <div>
                        <div>Scrape selectors</div> 
                        <div>{"Include {html}"}</div>
                        <AutoExpandingTextarea myValue={promptObject.scrapeSelctors} setValue={handleScrapeSelectorsChange}/>
                    </div>
                    <div>
                        <div>Link filtering</div> 
                        <div>{"Include {links}"}</div>
                        <AutoExpandingTextarea myValue={promptObject.linkFilter} setValue={handleLinkFilterChange}/>
                    </div>
                </div>


            </div>
            )
    }
    else{
        return(<div></div>)
    }
}

export default Prompts


export function generateNewId(ids:Array<string>):string {
  let newID = generateRandomId().toString()

  if(ids.find(n=>n===newID)){
    newID=generateNewId(ids)
  }
  return newID
}

export function generateRandomId():number {
    const min = 1000000
    const max = 9999999
    const result=Math.floor(Math.random() * (max - min + 1)) + min
    return result
}

  
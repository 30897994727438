import styles from './URLSection.module.css'
import URLBox from '../URLBox'
import FetchComponent from '../FetchComponent'
import ScrapeComponent from '../ScrapeComponent'
import axios from 'axios'
import React,{ useState } from 'react'
import { testURL } from '../../../../api'

function isNumber(value) {
  return typeof value === 'number' && isFinite(value)
}

const URLSection = ({ currentCompany, setUrls, urls, setTestRes, testRes }) => {
  const [updateTime, SetUpdateTime] = useState('')
  const [isApiCall,setIsApiCall]=useState(false)

  const handleApiCallChange=(e)=>{
    setIsApiCall(prev=>!prev)
  }

  const handleAddManualUrl = () => {
    setUrls((prevURLs) =>
      prevURLs.concat([
        {
          url: '',
          type: 'manual',
          multiple: false,
          subPage: '',
          startNumber: '',
        },
      ])
    )
  }

  const handleAddUrlAutomation = () => {
    setUrls((prevURLs) =>
      prevURLs.concat([
        {
          url: '',
          type: 'automated',
          multiple: false,
          subPage: '',
          startNumber: '',
          isFetch: true,
          fethOptions: [],
          scrapeOptions: [],
        },
      ])
    )
  }
  const handleSetFetchOptions = (i, value) => {
    urls[i].fethOptions = value
    setUrls([...urls])
  }
  const handleSetScrapeOptions = (i, i2, value) => {
    urls[i].scrapeOptions[i2] = value
    setUrls([...urls])
  }

  const handleFetchChange = (i) => {
    /*         urls[i].isFetch=!urls[i].isFetch
        setUrls([...urls]) */
  }

  const handleTestClick = async () => {
    let resList = []
    for (let url of urls) {
      if (url.type === 'automated') {
        if (url.isFetch) {
          let body = {
            targetUrl: url.url,
            multiple: url.multiple
              ? { subPage: url.subPage, startNumber: url.startNumber }
              : undefined,
            commands: url.fethOptions,
          }
          let res = await testURL(body)
          resList = resList.concat(res.data)
        } else {
          console.log(url.scrapeOptions)
        }
      } else {
        resList.push(url)
      }
    }
    setTestRes(resList)
  }

  const handleAddScrapeOption = (i) => {
    urls[i].scrapeOptions.push([])
    setUrls([...urls])
  }

  const handleDeleteScrapeComponent = (i, i2) => {
    urls[i].scrapeOptions = urls[i].scrapeOptions.filter(
      (_, index) => index !== i2
    )
    setUrls([...urls])
  }

  const handleUpdateChange = (e) => {
    let val = e.target.value
    let num = Number(val)

    if (isNumber(num) || val === '') {
      SetUpdateTime(val)
    }
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
      <div className={styles.urlContainer}>
        <div>URLS</div>
        <div>
          <span>Api call</span>
          <input checked={isApiCall} onChange={handleApiCallChange} type="checkbox"/>
        </div>
        {isApiCall &&
          <div>
            asdas
          </div>
        }
        {!isApiCall &&
        <div>
          <div className={styles.rowGap}>
            <button onClick={() => handleAddManualUrl()}>add maunal</button>
            <button onClick={() => handleAddUrlAutomation()}>
              add automation
            </button>
          </div>

          <div className={styles.rowGap}>
            <span>update in minutes: </span>
            <input value={updateTime} onChange={handleUpdateChange} />
            <span>m</span>
          </div>

        {urls.map((n, index) => {
          return n.type === 'manual' ? (
            <div key={index} className={styles.oneUrlBox}>
              <URLBox n={n} index={index} urls={urls} setUrls={setUrls} />
            </div>
          ) : (
            <div className={styles.oneUrlBox} key={index}>
              <URLBox n={n} index={index} urls={urls} setUrls={setUrls} />
              <div className={styles.fetchOrScrape}>
                <div style={n.isFetch ? { color: 'green' } : {}}>fetch</div>
                <div style={n.isFetch ? {} : { color: 'green' }}>scrape</div>
                <button onClick={() => handleFetchChange(index)}>change</button>
              </div>
              {n.isFetch ? (
                <FetchComponent
                  options={n.fethOptions}
                  setOptions={(value) => handleSetFetchOptions(index, value)}
                />
              ) : (
                <div>
                  {n.scrapeOptions.map((option, index2) => {
                    return (
                      <ScrapeComponent
                        key={index2}
                        options={option}
                        setOptions={(value) =>
                          handleSetScrapeOptions(index, index2, value)
                        }
                        handleDeleteScrapeComponent={() =>
                          handleDeleteScrapeComponent(index, index2)
                        }
                      />
                    )
                  })}
                  <button onClick={() => handleAddScrapeOption(index)}>
                    add
                  </button>
                </div>
              )}
            </div>
          )
        })}

        <button onClick={() => handleTestClick()}>test</button>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '5px',
          marginLeft: '5px',
          maxHeight: '400px',
          overflowY: 'scroll',
        }}>

        {testRes.map((n) => {
          return <div>{n}</div>
        })}

      </div>
    </div>

      }

    </div>
  </div>
  )
}
export default URLSection

import styles from './Header.module.css'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'

const Header = ({ DB, DB_old, currentCompany }) => {
  let check = localStorage.getItem('emeric-local')
  const [checked, setChecked] = useState(check ? true : false)

  const companyName=currentCompany?.name?currentCompany.name:"-"

  const handleCheckChange = (e) => {
    if (checked) {
      localStorage.removeItem('emeric-local')
    } else {
      localStorage.setItem('emeric-local', 'a')
    }
    setChecked((prev) => !prev)
  }


  const handleChatNavigate = () => {
    if (companyName !== '-') {
      const url=`https://emeric.ai/demo/${currentCompany.name}000`
      window.open(url, '_blank')
    }
  }


  return (
    <div
      id='header'
      className={styles.container}
      style={
        window.location.pathname.split('/')[1] === 'global'
          ? { display: 'none' }
          : {}
      }>
      <div className={styles.header}>
        <div
          className={styles.HeaderButtons}
          style={{
            pointerEvents: companyName === '-' ? 'none' : 'initial',
          }}>
          <Link
            className={styles.headerButton}
            to={`/company/${companyName}/data`}>
            Data
          </Link>

          <Link
            className={styles.headerButton}
            to={`/company/${companyName}/data2`}>
            Data2
          </Link>

          <Link
            className={styles.headerButton}
            to={`/company/${companyName}/logic`}>
            Logic
          </Link>

          <Link
            className={styles.headerButton}
            to={`/company/${companyName}/context`}>
            Context
          </Link>

          <Link
            className={styles.headerButton}
            to={`/company/${companyName}/Dashboard`}>
            Dashboard
          </Link>
          {/*           <Link
            className={styles.headerButton}
            to={`/company/${companyName}/test`}>
            Test
          </Link> */}

          <Link
            className={styles.headerButton}
            to={`/company/${companyName}/automation`}>
            Automation
          </Link>
          <Link
            className={styles.headerButton}
            to={`/company/${companyName}/automation2`}>
            Automation2
          </Link>
{/*           <Link
            className={styles.headerButton}
            to={`/company/${companyName}/AGI`}>
            AGI
          </Link> */}
          <Link
            className={styles.headerButton}
            to={`/company/${companyName}/settings`}>
            Settings
          </Link>

          <div
            className={styles.headerButton}
            onClick={() => handleChatNavigate()}>
            Chat{' '}
          </div>

          <div className={styles.headerLogo}>Emeric.ai</div>
        </div>
      </div>
    </div>
  )
}
export default Header

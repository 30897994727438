import React, { useState } from 'react'
import styles from './Automation.module.css'
import { FaArrowRight, FaArrowLeft } from 'react-icons/fa'
import URLSection from './components/URLSection'
import ScrapeSection from './components/ScrapeSection'

const activeButton = {
  color: 'black',
  cursor: 'pointer',
}
const inActiveButton = {
  color: 'grey',
}

const Automation = ({ currentCompany }) => {
  const [section, setSection] = useState(1)
  const [urls, setUrls] = useState([])
  const [testRes, setTestRes] = useState([])

  const goLeft = () => {
    setSection(Math.max(1, section - 1))
  }

  const goRight = () => {
    setSection(Math.min(3, section + 1))
  }

  return (
    <div className={styles.container}>
      <div className={styles.innerContainer}>
        <div className={styles.changePartBox}>
          <FaArrowLeft
            onClick={() => goLeft()}
            style={section === 1 ? inActiveButton : activeButton}
          />
          <FaArrowRight
            onClick={() => goRight()}
            style={section !== 3 ? activeButton : inActiveButton}
          />
        </div>

        {section === 1 && (
          <URLSection
            urls={urls}
            setUrls={setUrls}
            currentCompany={currentCompany}
            testRes={testRes}
            setTestRes={setTestRes}
          />
        )}
        {section === 2 && (
          <ScrapeSection urls={urls} currentCompany={currentCompany} />
        )}
        {section === 3 && <div>Automatisations</div>}
      </div>
    </div>
  )
}

export default Automation

import ChartHolder from './ChartHolder/index.js'
import ChatHolder from './ChatHolder/index.js'
import React, { useState } from 'react'

const Dashboard = ({ currentCompany }) => {
  const [chats,setChats] = useState(currentCompany.chats)
  console.log(chats.filter(n=>n.language))
  return (
    <div style={{ display: 'flex', fontFamily: 'Poppins' }}>
      <ChartHolder chats={chats.filter((n) => !n.local)} />
      <ChatHolder chats={chats} currentCompany={currentCompany} setChats={setChats}/>
    </div>
  )
}

export default Dashboard

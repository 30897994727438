import React, { useState } from 'react';
import styles from './AutoCompleteInput.module.css'; // Import your CSS module

interface AutocompleteInputProps {
  suggestions: string[];
  value:string;
  onChange:(e:React.ChangeEvent<HTMLInputElement>)=>void;
  setValue:(n:string)=>void;
  placeHolder?:string;
  go?:()=>void;
}

const AutocompleteInput: React.FC<AutocompleteInputProps> = ({ suggestions,value,onChange,setValue,placeHolder,go }) => {

  const suggestion = value && suggestions.find(n => n.toLowerCase().includes(value.toLowerCase()));

  const handleKeyDown =(event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Meta' && suggestion) {
      setValue(suggestion);

    }
    else if (event.key === 'Enter' ) {
      if(go){
        go()
      }
    }
  }

  return (
    <div className={styles.container}>
      <input
        className={styles.inputField}
        value={value}
        onChange={onChange}
        onKeyDown={handleKeyDown}
        placeholder={placeHolder}
      />
        
      {(suggestion && suggestion!==value) && <div className={styles.suggestion}>{suggestion}</div>}

    </div>
  );
};

export default AutocompleteInput;

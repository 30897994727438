import {  postCompany } from '../api'

const addCompany = async ({
  newCompanyName,
  setCompanyNames,
  setNewCompanyPopUp,
}) => {
  if (!newCompanyName) {
    alert('Please enter a company name')
    return
  }
  try {
    const body = {
      name: newCompanyName.toLocaleLowerCase().replaceAll(' ', '-'),
    }
    await postCompany(body)
    setCompanyNames((prev) => [...prev, body])
    setNewCompanyPopUp(false)
  } catch (error) {
    console.error('Error creating company:', error)
  }
}

export default addCompany

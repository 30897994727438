import styles from './save.module.css';
import React, { useState, useEffect } from 'react';

const Save = ({ text }) => {
/*   const [saveText, setSaveText] = useState("");

  useEffect(() => {
    const company = DB.find(n => n.name === companyName);
    const staticCompany = DB_old.find(n => n.name === companyName);

    let newSaveText = "";

    if (company && staticCompany) {
      for (let headline of company.headlines) {
        let headlineCopy = staticCompany.headlines.find(n => n.id === headline.id);

        if (!headlineCopy) {
          newSaveText += `New headline ${headline.name}\n\n`;
        } else {
          if (headline.headlineInfo !== headlineCopy.headlineInfo) {
            newSaveText += `HeadlineInfo changed ${headline.name}\n\n`;
          }

          for (let note of headline.notes) {
            if (note.new) {
              newSaveText += `DB note ${note.title} is new\n\n`;
            } else if (note.deleted) {
              newSaveText += `DB note ${note.title} is deleted\n\n`;
            } else {
              let copy = headlineCopy.notes.find(n => n.id === note.id);
              if (JSON.stringify(copy) !== JSON.stringify(note)) {
                newSaveText += `DB note ${note.title} is modified\n\n`;
              }
            }
          }
        }
      }

      if (company.context !== staticCompany.context) {
        newSaveText += `context has changed\n\n`;
      }

      if (company.contextProd !== staticCompany.contextProd) {
        newSaveText += `contextProd has changed\n\n`;
      }

      if (company.shopify?.companyName !== staticCompany.shopify?.companyName) {
        if(!staticCompany.shopify && company.shopify?.companyName==="" ){

        }
        else{
          newSaveText += `shopifyname has changed\n\n`;
        }
      }

      if (JSON.stringify(company.demo) !== JSON.stringify(staticCompany.demo)) {
        newSaveText += `demo has changed\n\n`;
      }

      if (company.email !== staticCompany.email) {
        newSaveText += `email has changed\n\n`;
      }

      if (JSON.stringify(company.dragNotes) !== JSON.stringify(staticCompany.dragNotes)) {
        newSaveText += `Logic has changed\n\n`;
      }
    }

    setSaveText(newSaveText);
  }, [companyName, DB, DB_old]); */

  return (
    <div className={styles.container}>
      <pre>{text}</pre>
    </div>
  );
}

export default Save;

import styles from './FetchComponent.module.css'

const FetchComponent = ({ options, setOptions }) => {
  const handleSelect = (index) => (e) => {
    options[index].value = e.target.value
    setOptions([...options])
  }

  const handleAdd = () => {
    options.push({ value: '-', input: '' })
    setOptions([...options])
  }

  const handleRemove = (index) => {
    options = options.filter((_, i) => i !== index)
    setOptions([...options])
  }

  const handleInputChange = (index1) => (e) => {
    options[index1].input = e.target.value
    setOptions([...options])
  }

  return (
    <div className={styles.container}>
      {options.map((k, index1) => {
        return (
          <div key={index1} className={styles.one}>
            <select onChange={handleSelect(index1)} value={k.value}>
              {[
                '-',
                'classnames',
                'classnames starts with',
                'components',
                'ids',
                'ids starts with',
              ].map((n, index2) => {
                return (
                  <option key={index2} value={n}>
                    {' '}
                    {n}{' '}
                  </option>
                )
              })}
            </select>
            <input value={k.input} onChange={handleInputChange(index1)} />

            <button onClick={() => handleRemove(index1)}>x</button>
          </div>
        )
      })}
      <button onClick={() => handleAdd()}> add </button>
    </div>
  )
}

export default FetchComponent

import React, { useState } from 'react'
import { IoCloseOutline } from 'react-icons/io5'
import styles from './PopUp.module.css'

const PopUp = ({ text, save, setPopUp }) => {
  const [inputValue, setInputValue] = useState('')
  const handleInputChange = (e) => {
    setInputValue(e.target.value)
  }

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <IoCloseOutline onClick={() => setPopUp(false)} />
      </div>
      {text}
      <input value={inputValue} onChange={handleInputChange} />
      <button onClick={() => save(inputValue)}>save</button>
    </div>
  )
}

export default PopUp

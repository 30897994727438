import { RiDeleteBin6Line } from "react-icons/ri"
import styles from './Note.module.css'
import { Company, note2 } from "types"
import AutoExpandingTextarea from "../AutoExpandingTextarea";
import { generateNewId } from "functions/id";
import { IoIosAddCircleOutline } from "react-icons/io";
import { FiTriangle } from "react-icons/fi";
import { HiOutlineSquares2X2 } from "react-icons/hi2";

interface INote{
    note:note2;
    currentCompany:Company;
    staticCurrentCompany:Company;
    refreshDB:()=>void;
    handleAddNote:(note?:note2)=>void;
}

const Note:React.FC<INote>=({note,currentCompany,staticCurrentCompany,refreshDB,handleAddNote})=>{
    const staticNote=staticCurrentCompany.notes.find(n=>n._id===note._id)


    const handleDeleteNote = async (note:note2) => {
        const isConfirmed = window.confirm(
        `Are you sure you want to delete note ${note.title}?`
        )
        if (isConfirmed) {
            if(note.new){
                if(note.parentId){
                    const parentNote=currentCompany.notes.find(n=>n._id === note.parentId)
                    if(parentNote){
                        parentNote.notes=parentNote.notes.filter(n=>n._id!==note._id)
                    }
                }
                else{
                    currentCompany.notes=currentCompany.notes.filter(n=>n._id!==note._id)
                }
            }
            else{
                note.deleted=true

            }


            refreshDB()
        }
    }

    

    const onNoteTextChange=(note:note2) => (e:React.ChangeEvent<HTMLInputElement>) => {
        note.text = e.target.value
        refreshDB()
    }

    const onNoteTitleChange=(note:note2) => (e:React.ChangeEvent<HTMLInputElement>) => {
        note.title = e.target.value
        refreshDB()
    }


    const handleHeadlineChange=()=>{
        note.isHeadline=!note.isHeadline
        refreshDB()
    }

    const handleShowChildNotes=()=>{
        note.showChildren=note.showChildren?false:true
        console.log(22)
        console.log(note.showChildren)
        refreshDB()
    }


    return(
        <div className={note.isHeadline?styles.containerHeadline:styles.container} key={note._id}>
            <div className={styles.noteHeader}>
                <HiOutlineSquares2X2 onClick={()=>handleHeadlineChange()}/>
                <RiDeleteBin6Line onClick={() => handleDeleteNote(note)}/>
            </div>

            <input
                value={note.title}
                onChange={onNoteTitleChange(note)}
                className={styles.notesTitle}
                style={
                !staticNote || staticNote.title !== note.title
                    ? { borderColor: 'red' }
                    : {}
                }
            />

            <AutoExpandingTextarea
                myText={note.text}
                change={onNoteTextChange(note)}
                redBorder={
                !staticNote || staticNote.text !== note.text
                }
            />
            {
                note.isHeadline &&
                <div className={styles.noteList}>
                    <div className={styles.buttons}>
                        <FiTriangle onClick={()=>handleShowChildNotes()} style={note.showChildren?{}:{transform:"rotate(180deg)"}}/>
                        <IoIosAddCircleOutline onClick={()=>handleAddNote(note)}/>
                    </div>

                    {note.showChildren && note.notes.map((childNote,i)=>{
                        return <Note key={i} note={childNote} currentCompany={currentCompany} staticCurrentCompany={staticCurrentCompany} refreshDB={refreshDB} handleAddNote={handleAddNote}/>
                    })}
                </div>
            }
            </div>


)}


export default Note






import { Company, urlChild, urlObject, headline2,Iurl } from 'types'
import styles from './AGI.module.css'
import React, { useEffect, useState } from 'react';
import { IoIosAddCircleOutline } from 'react-icons/io'
import AutocompleteInput from 'components/AutocompleteInput';
import { RxCross2 } from "react-icons/rx";
import { generateNewId } from 'functions/id';
import { getUrlObject, getUrlObjs, updateUrlObj,moreLinks} from 'api';
import { FaRegSave } from "react-icons/fa";
import PathnameMenu from './components/PathnameMenu';


interface IAGI {
    currentCompany: Company;
    localUrlObjects: Array<urlObject>
}



interface website{
    url:string
}




const AGI: React.FC<IAGI> = () => {
    const [started, setStarted] = useState(false)
    const [linkList, setLinkList] = useState<Array<Iurl>>([])
    const [linkList_old, setLinkList_old] = useState<Array<Iurl>>([])
    const [website,setWebsites]=useState<Array<website>>([])
    const [adding,setAdding]=useState<null|headline2>(null)

    
    const [url, setUrl] = useState("")
    const handleUrlChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setUrl(e.target.value)
    }

    const [exampleTitles, setExampleTitles] = useState<Array<string>>(["Payments", "Products", "Our Services and subscriptions that we sell", "Contact information, Addresses, Numbers",
        "Orders,returns,order tracking, reimbursements and evertyhing related to delievery", "Privacy policy", "About us, History, Why us", "Our offices/facilities", "How to come work for us/How to start with us/How to buy our service"])

    const startFormatting = () => {
        const notes=linkList.filter(n=>!n.deleted && n.selected || n.headlineId && n.headlineId!=="-" )
    }
    const reRender = () => {
        setLinkList([...linkList])
    }


    const handleAddChange=(e:React.ChangeEvent<HTMLInputElement>)=>{
        if(adding){
            adding.name=e.target.value
            setAdding({...adding})
        }
    }

    const handleReStart=()=>{
        setStarted(false)
        setLinkList([])
        setLinkList_old([])
    }


    const [showDeleted,setShowDeleted]=useState(false)

    const handleSave = async () => {
        for(const link of linkList){
            const copy=linkList_old.find(n=>n.url===url)
            if(JSON.stringify(copy)!==JSON.stringify(link)){
                console.log("changed")
                await updateUrlObj({obj:link})
            }
        }
    }

    const [fetching,setFetching]=useState(false)



    const handleGo = async () => {
        try {
            setStarted(true)
            setFetching(true)
            
            const urlList:Array<Iurl>=await getUrlObjs({url,fetchNew:false})
            urlList.sort((a,b)=>{
                const paths=a.pathname.split("/")
                const paths2=b.pathname.split("/")

                const aa=linkList.find(n=>n.pathname.startsWith(`/${paths[0]}`))
                const bb=linkList.find(n=>n.pathname.startsWith(`/${paths2[0]}`))
                if(aa && bb && aa==bb){
                    return paths.length-paths2.length
                }
                else if(aa && bb || !aa && !bb){
                    return a.pathname.localeCompare(b.pathname)
                }
                else if(aa && !bb){
                    return 1
                }
                else{
                    return -1
                }
            })
            setLinkList(urlList)
            setLinkList_old(JSON.parse(JSON.stringify(urlList)))
            setFetching(false)
        }
        catch (e) {
            console.log(e)
        }
    }

    const handleMoreLinks=()=>{

    }
    console.log(linkList)

    const handleAddUrl=()=>{
        if(newUrl){
            setNewUrl("")
        }
    }
    
    const [newUrl,setNewUrl]=useState("")
    const handleNewUrlChange=(e:React.ChangeEvent<HTMLInputElement>)=>{
        setNewUrl(e.target.value)
    }
        return (
            <div className={styles.container}>
                {
                    !started
                        ? <div className={styles.start}>
                            <div>Url:</div>
                            <AutocompleteInput setValue={setUrl} value={url} onChange={handleUrlChange} suggestions={website.map(n=>n.url)} go={handleGo}/>
                            <div className={styles.goButton} onClick={() => handleGo()}>GO</div>
                        </div>
                        : <div className={styles.fullContainer} >
                            {fetching 
                            ?<div>fetching...</div>
                            :<button onClick={()=>handleMoreLinks()}>more links</button>
                        }
                            <button onClick={()=>handleReStart()}>restart</button>
    
                            <div className={styles.exampleTitles}>
                                {exampleTitles.map((n, i) => {
                                    return (
                                        <div key={i} className={styles.exampleTitle}>
                                            {n}
                                        </div>
                                    )
                                }
                                )}
                            </div>
                            <div className={styles.innerContainer}>
                                <button onClick={() => handleSave()}>save</button>
                                <div>
                                    show deleted?
                                    <input type='checkbox' onChange={()=>setShowDeleted(prev=>!prev)} />
                                </div>
                                {
                                    adding
                                    ?<div style={{display:"flex",gap:"3px",alignItems:"center"}}>
                                        <FaRegSave/>
                                        <input value={adding.name} onChange={handleAddChange}/>
                                        <RxCross2 onClick={()=>setAdding(null)}/>
                                        </div>
                                    :<IoIosAddCircleOutline  />
    
                                }
                                <div className={styles.titles}>
{/*                                     {urlObj?.headlines.map((n, i) => {
                                        return (
                                            <div key={i} className={styles.titleHolder}>
                                                    <div className={styles.exampleTitle}>{n.name}</div>
                                                    <select value={n.type} onChange={handleTypeChange(i)}>
                                                        <option value="string check">string check</option>
                                                        <option value="Prompt check">Prompt check</option>
                                                        <option value="one note">one note</option>
                                                    </select>
    
                                                <RxCross2 onClick={()=>handleDeleteTitle(i)}/>
                                            </div>
                                        )
                                    }
                                    )} */}
                                </div>

                                <div style={{display:"flex",gap:"10px"}}>
                                    <div>new url:</div>
                                    <input style={{width:"300px"}} placeholder='https://example.com' value={newUrl} onChange={handleNewUrlChange}/>
                                    <button onClick={()=>handleAddUrl()}>add</button>
                                </div>
    
                                {linkList.map((n, i) => {
                                    return <PathnameMenu key={i} obj={n} linkList={linkList} reRender={reRender} showDeleted={showDeleted}/>
                                })}

                            </div>
                            <button onClick={() => startFormatting()}>go</button>
    
    
                        </div>
                }
            </div>
        )


}


export default AGI
import { saveNotes, saveAll,saveLogic,handleChangePassword ,saveMultipleNotes,saveHeadlines2,saveAutomationObj,deleteNote,putNotes} from '../api'
const fields = ['contextObj','email',"demo","languages","demoIsDisplayed","logoUrl","priorityLanguages"]

const saveOneCompany = async (company, company_old) => {


  //save notes if modified
  if (
    JSON.stringify(company.headlines) !== JSON.stringify(company_old.headlines)
  ) {
    company.headlines = company.headlines.filter((n) => !n.deleted)
    for (var headline of company.headlines) {
      delete headline.new
      headline.notes = headline.notes.filter((n) => !n.deleted)
    }
    for (let note of company.headlines.map((n) => n.notes).flat()) {
      delete note.new
      delete note.embedding
    }
    await saveNotes({ headlines: company.headlines }, company._id)
    company_old.headlines = JSON.parse(JSON.stringify(company.headlines))
  }

  if (
    (JSON.stringify(company.logic) !==
    JSON.stringify(company_old.logic)) ||
    (JSON.stringify(company.languages) !==
    JSON.stringify(company_old.languages))
) {
    await saveLogic({logic:company.logic,languages:company.languages},company._id)
    company_old.logic = JSON.parse(JSON.stringify(company.logic))
    company_old.languages = JSON.parse(JSON.stringify(company.languages))
}

  if(company.password){
    await handleChangePassword({password:company.password},company._id)
    delete company.password
    console.log(company)
  }


  const newNotes=company.notes.filter(n=>n.new)


  if(JSON.stringify(company.automations)!==JSON.stringify(company_old.automations)){
    for(const automation of company.automations){
      if(automation.new){
        const automationOldId=automation._id
        delete automation.new;
        delete automation._id;
        const res=await saveAutomationObj({obj:automation})
        const newId=res.data
        for(let note of newNotes){
          if(note.automationId===automationOldId){
            note.automationId=newId;
          }
        }
      }
    }

  }

  if(newNotes.length>0){
    newNotes.forEach(n=>{
      delete n.new
    })
    const res=await saveMultipleNotes({newNotes},company._id)

    for(let i=0;i<newNotes.length;i++){
      const note=newNotes[i]
      const id=res.data[i]
      note._id=id
    }
  }

  if(JSON.stringify(company.notes!==JSON.stringify(company_old.notes))) {
    const deleteIds=[]
    const modifiedList=[]
    for(let note of company.notes){
      if(note.deleted){
        deleteIds.push(note._id)
      }
      else{
        const oldNote=company_old.notes.find(n=>n._id===note._id)
        if(oldNote && (JSON.stringify(note)!==JSON.stringify(oldNote))){
          modifiedList.push(note)
        }
      }
    }
    if(deleteIds.length>0){
      await deleteNote({deleteIds:deleteIds},company._id)
      company.notes=company.notes.filter(n=>!n.deleted)
    }
    if(modifiedList.length>0){
      await putNotes({notes:modifiedList},company._id)
    }
    company_old.notes=JSON.parse(JSON.stringify(company.notes))
  }

  
  if(JSON.stringify(company.headlines2)!==JSON.stringify(company_old.headlines2)){
    for(let headline of company.headlines2){
      delete headline.new
    }
    await saveHeadlines2({headlines:company.headlines2},company._id)
    company_old.headlines2=JSON.parse(JSON.stringify(company.headlines2))
  }


  let companyCopy = {}
  let company_oldCopy = {}

  for (let key of fields) {
      companyCopy[key] = company[key]
      company_oldCopy[key] = company_old[key]
  }

  if (JSON.stringify(companyCopy) !== JSON.stringify(company_oldCopy)) {
    await saveAll(companyCopy, company._id)
    for (let key of fields) {
      company_old[key] = JSON.parse(JSON.stringify(companyCopy[key]))
    }
  }
}

export default saveOneCompany

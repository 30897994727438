import Draggable, { DraggableData, DraggableEvent } from 'react-draggable';
import AutoExpandingTextarea from '../../../../components/AutoExpandingTextarea'
import React, { useRef } from 'react'
import styles from  './Form.module.css'
import { RiDeleteBin6Line } from 'react-icons/ri'
import { IoIosAddCircleOutline } from 'react-icons/io'
import {logicObj,LangNote,ConnectionObj,Company} from '../../../../types'

const connectedStyle = {
    backgroundColor: 'green',
}



interface IForm{
  handleDeleteDragNote:(id:string)=>void;
  currentCompany:Company;
  currentConnection:ConnectionObj|null;
  handleDrag:()=>void;
  setDB:(DB:Array<Company>)=>void;
  DB:Array<Company>;
  logicObj:logicObj;
  handleTitleClick:(logicObj:logicObj)=>void;
  curLang:string;
}

  
const Form:React.FC<IForm> = ({
    handleDeleteDragNote,
    currentCompany,
    currentConnection,
    handleDrag,
    setDB,
    DB,
    logicObj,
    handleTitleClick,
    curLang
  }) => {
    const draggableRef = useRef(null)

    const handleTitleChange = (text:string) => {
      logicObj.languages[curLang].title=text
      setDB([...DB])
    }
    
    const handleAddArea = () => {    
        logicObj.texts.unshift("input")

        for(const lang of Object.keys(logicObj.languages)){
          logicObj.languages[lang].texts.push("")
        }
    
        setDB([...DB])
    }

    const handleDeleteTextArea = (index:number) => {
        if(logicObj.texts.length > 2 && index !== (logicObj.texts.length-1)){
            logicObj.texts.splice(index,1)
            for(const lang of Object.keys(logicObj.languages)){
              logicObj.languages[lang].texts.splice(index,1)
            }
            setDB([...DB])
        }
    }
    
    const handleChangeArea = (index:number, text:string) => {
      logicObj.languages[curLang].texts[index] = text
      setDB([...DB])
    }
    
    const handleStop = (e: DraggableEvent, data: DraggableData) => {
        if (logicObj.x !== data.x || logicObj.y !== data.y) {
            logicObj.x = data.x
            logicObj.y = data.y
            handleDrag()
        }
    }


    const handleEndpointSelectChange=(e:React.ChangeEvent<HTMLSelectElement>)=>{
        if(e.target.value === "#"){
          logicObj.texts = logicObj.texts.slice(0,2)
          for(const lang of Object.keys(logicObj.languages)){
            logicObj.languages[lang].texts=logicObj.languages[lang].texts.slice(0,2)
          }
        }
        else if(e.target.value === "!"){
          for(const lang of Object.keys(logicObj.languages)){
            logicObj.languages[lang].texts[1]=""
          }
        }

        logicObj.endpoint=e.target.value
        setDB([...DB])
    }

    const handleInputTypeChange=(texts:Array<string>,i:number)=>(e:React.ChangeEvent<HTMLSelectElement>)=>{
      texts[i]=e.target.value
      setDB([...DB])
    }


    return(
    <Draggable
      onStop={handleStop}
      defaultPosition={{ x: logicObj.x, y: logicObj.y }}
      nodeRef={draggableRef}>
      <div className={styles.container} ref={draggableRef}>
        <div className={styles.myNote}>
          <div className={styles.myNoteHeader}>
            <div className={styles.ballAndInput}>
              <div
                className={logicObj.id}
                style={
                  currentConnection?.id === logicObj.id
                    ? connectedStyle
                    : {}
                }
                id={styles.titleBall}
                onClick={() => handleTitleClick(logicObj)}
              />

              <select value={logicObj.endpoint} onChange={handleEndpointSelectChange}>
                <option value="!">!email</option>
                <option value="#">#shopify</option>
              </select>

            </div>
            <AutoExpandingTextarea
              myValue={logicObj.languages[curLang].title}
              setValue={handleTitleChange}
            />
            {logicObj.endpoint!=="#" &&
              <IoIosAddCircleOutline onClick={handleAddArea} style={{color:"white",cursor:"pointer",fontSize:"20px",marginLeft:"4px"}}/>
            }
            <RiDeleteBin6Line onClick={()=>handleDeleteDragNote(logicObj.id)} style={{color:"white",cursor:"pointer",fontSize:"20px",marginLeft:"4px"}}/>
          </div>

          {logicObj.texts.map((area, i) => {
            const curText=logicObj.languages[curLang].texts[i]
            return (
              <div className={styles.textAreaHolder} key={i}>
                <div className={styles.holder}>
                {
                    (logicObj.texts.length-1 !== i || logicObj.endpoint==="#") &&
                    <select value={area} onChange={handleInputTypeChange(logicObj.texts,i)}>
                        <option value="input">input</option>
                        <option value="textarea">textarea</option>
                    </select>
                }

                <AutoExpandingTextarea
                    myValue={curText}
                    setValue={(text:string)=>handleChangeArea(i,text)}
                />
                </div>
                {(i!==logicObj.texts.length-1 && logicObj.endpoint!=="#") &&
                                <RiDeleteBin6Line onClick={()=>handleDeleteTextArea(i)} style={{color:"white",cursor:"pointer",fontSize:"20px",marginTop:"10px",marginLeft:"5px"}}/>
                }

{/*                 <div className={styles.ballContainer}>
                  <div
                    className={area.id}
                    id={styles.ball}
                    style={
                      currentConnection?.id === area.id ? connectedStyle : {}
                    }
                     onClick={() => handleTextClick(area.id)}
                   />
                </div> */}
              </div>
            )
          })}
        </div>
      </div>
    </Draggable>
    )
  }


export default Form
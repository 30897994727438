import styles from './ScrapeComponent.module.css'

const ScrapeComponent = ({
  options,
  setOptions,
  handleDeleteScrapeComponent,
}) => {
  const handleSelect = (index) => (e) => {
    options[index].value = e.target.value
    setOptions([...options])
  }

  const handleAddOption = () => {
    options.push({ value: '-', input: '' })
    setOptions([...options])
  }

  const handleRemove = (index) => {
    options = options.filter((_, i) => i !== index)
    setOptions([...options])
  }

  const handleInputChange = (index1) => (e) => {
    options[index1].input = e.target.value
    setOptions([...options])
  }

  const optionsList = [
    '-',
    'classname',
    'classname starts with',
    'component',
    'id',
    'id starts with',
    'classnames',
    'classnames starts with',
    'components',
    'ids',
    'ids starts with',
    'click',
    'links',
  ]

  return (
    <div>
      <div className={styles.container}>
        <button onClick={() => handleDeleteScrapeComponent()}>x</button>
        {options.map((option, index) => {
          return (
            <div
              style={{
                width: '100%',
                display: 'flex',
                gap: '10px',
                marginTop: '10px',
              }}
              key={index}>
              <select onChange={handleSelect(index)} value={option.value}>
                {optionsList.map((n, index3) => {
                  return (
                    <option key={index3} value={n}>
                      {' '}
                      {n}{' '}
                    </option>
                  )
                })}
              </select>
              {option.value !== 'click' && option.value !== 'links' && (
                <input
                  value={option.input}
                  onChange={handleInputChange(index)}
                />
              )}
              <button onClick={() => handleRemove(index)}>x</button>
            </div>
          )
        })}

        <button className={styles.addButton} onClick={() => handleAddOption()}>
          {' '}
          add{' '}
        </button>
      </div>
    </div>
  )
}

export default ScrapeComponent

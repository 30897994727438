function getOneCompanyCount(companyName,DB, DB_old,promptObject,promptObject_old) {
    let count =[] 

    if(promptObject&&promptObject_old){
        for (let key of Object.keys(promptObject)){
            if(key!=="_id" && (promptObject[key] !== promptObject_old[key])){
                count.push(`${key} prompt is changed`)
            }
        }
    }

    const company=DB.find(n=>n.name===companyName);
    const staticCompany=DB_old.find(n=>n.name===companyName)


    if(company && staticCompany){
        if (JSON.stringify(staticCompany.demo) !== JSON.stringify(company.demo)) {
            count.push("Demo settings have changed")
        }
        if (company.shopify?.companyName !== staticCompany.shopify?.companyName) {
            if(!staticCompany.shopify && company.shopify?.companyName==="" ){
                null
            }
            else{
                count.push("Shopify store name has changed")
            }
        }
        if (company.shopify && company.shopify?.apiKey !== "") {
            count.push("Shopify api key has changed")
        }
    
        if (company.email !== staticCompany.email) {
            count.push("Email has changed")
        }
        if (company.contextObj.production !== staticCompany.contextObj.production) {
            count.push("Context production has changed")
        }
        if (company.contextObj.development !== staticCompany.contextObj.development) {
            count.push("Context development has changed")
        }

        if (JSON.stringify(company.priorityLanguages) !== JSON.stringify(staticCompany.priorityLanguages)) {
            count.push("Priority languages has changed")
        }


        if (JSON.stringify(company.contextObj.time) !== JSON.stringify(staticCompany.contextObj.time)) {
            count.push("Context time settings has changed")
        }

        if (company.demoIsDisplayed!==staticCompany.demoIsDisplayed) {
            count.push("Demo display changed")
        }

        if (company.logoUrl!==staticCompany.logoUrl) {
            count.push("Demo display changed")
        }

        if(JSON.stringify(company.headlines2) !== JSON.stringify(staticCompany.headlines2)){
            count.push("headlines2 has changed")
        }
        for(let note of company.notes){
            if(note.deleted){
                count.push(`Deleted note ${note.title}`)
            }
            else if(note.new){
                count.push(`New note ${note.title}`)
            }
            const oldOne=staticCompany.notes.find(n=>n._id===note._id)
            if(oldOne && JSON.stringify(oldOne) !== JSON.stringify(note)){
                count.push(`Note modified ${note.title}`)
            }
        }

        if(company.password){
            count.push("new password set")
        }

        if (
            (JSON.stringify(company.logic) !==
            JSON.stringify(staticCompany.logic)) ||
            (JSON.stringify(company.languages) !==
            JSON.stringify(staticCompany.languages))
        ) {
            count.push("Logic has changed")
        }
    
        const staticNotes = staticCompany.headlines.map((n) => n.notes).flat()
    
        //add new headliens
        company.headlines.filter((n) => n.new).forEach(n=>{
            count.push(`New headline ${n.name}`)
        })

        //add deleted headlines
        company.headlines.filter((n) => n.deleted).forEach(n=>{
            count.push(`Deleted headline ${n.name}`)
        })
    
    
        //add deleted notes
        company.headlines
            .filter((n) => !n.deleted)
            .map((n) => n.notes)
            .flat()
            .filter((n) => n.deleted)
            .forEach(n=>{
                count.push(`Deleted note ${n.title}`)
            })


        //add new notes
        company.headlines
            .filter((n) => !n.deleted)
            .map((n) => n.notes)
            .flat()
            .filter((n) => n.new)
            .forEach(n=>{
                count.push(`New note ${n.title}`)
            })
    

    
        const notDeletedOrNew = company.headlines
            .filter((n) => !n.deleted)
            .map((n) => n.notes)
            .flat()
            .filter((n) => !n.deleted && !n.new)

        for (let note of notDeletedOrNew) {
            let find = staticNotes.find((n) => n.id === note.id)
            if (find) {
            if (JSON.stringify(find) !== JSON.stringify(note)) {
                count.push(`Modified note ${note.title}`)
            }
            } else {
            console.log('error')
            }
        }
    
        for (let headline of company.headlines.filter(
            (n) => !n.deleted && !n.new
        )) {
            let find = staticCompany.headlines.find((n) => n.id === headline.id)
            if (find) {
                if(find.name!==headline.name){
                    count.push(`headline name ${find.name}`)

                }
                if (find.headlineInfo !== headline.headlineInfo) {
                    count.push(`Headlineinfo changed ${find.name}`)
                }
            }
        }
    }
  
    return count
  }
  
  export default getOneCompanyCount
  
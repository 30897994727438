import { IoIosAddCircleOutline } from 'react-icons/io';
import styles from './AutomationObj.module.css'
import { note2,AutomationObjWebPrompt, Company } from 'types'
import { RxCross2 } from 'react-icons/rx';
import AutoExpandingTextarea from 'components/AutoExpandingTextarea';
import Select, { SingleValue, StylesConfig } from 'react-select';
import React, { useState } from 'react';
import { generateNewId } from 'functions/id';
import axios from 'axios';


interface IOptions {
    value: string;
    label: JSX.Element;
  }
  type databox={
    title:string;
    text:string;
}

  type previewRes={
    data?:Array<databox>;
    title?:string;
    text?:string;
    originalText:string;
}

interface IAutomationObj{
    automation:AutomationObjWebPrompt;
    currentCompany:Company;
    DB:Array<Company>;
    setDB:(DB:Array<Company>)=>void;
    setPreView:(bool:boolean)=>void;
}


const AutomationObj:React.FC<IAutomationObj>=({automation,currentCompany,DB,setDB,setPreView})=>{
    const [loading,setLoading]=useState(false)

    const handleAutomationDelete=(automation:AutomationObjWebPrompt)=>{
        automation.deleted=true;
        setDB([...DB])
    }
    
    const changeHeadline=(automation:AutomationObjWebPrompt)=>(selectedOption:SingleValue<IOptions>)=>{
        console.log(selectedOption)
        automation.headline=selectedOption?.value||automation.headline
        setDB([...DB])
    }





    const handleMultipleNotesChange=(automation:AutomationObjWebPrompt)=>(e:React.ChangeEvent<HTMLInputElement>)=>{
        automation.multipleNotes=e.target.checked;
        setDB([...DB])
    }

    const handleUpdateInChange=(automation:AutomationObjWebPrompt)=>(e:React.ChangeEvent<HTMLInputElement>)=>{
        return
        console.log(e.target.value)
        if(e.target.value===""){
            automation.updateIn=null
        }
        else{
            const num=Number(e.target.value)
            console.log(num)
            if(num){
                automation.updateIn=num
            }
        }

        setDB([...DB])
    }

    const handleValueChange=(automation:AutomationObjWebPrompt,field:"title"|"url"|"selectors")=>(e:React.ChangeEvent<HTMLInputElement>)=>{
        automation[field]=e.target.value;
        setDB([...DB])
    }

    const handlePromptChange=(automation:AutomationObjWebPrompt,value:string)=>{
        console.log(value)
        automation.prompt=value
        setDB([...DB])
    }

    const handleHeaderChange=(automation:AutomationObjWebPrompt)=>(e:React.ChangeEvent<HTMLInputElement>)=>{

    }


    const setNewNotesToData=(automation:AutomationObjWebPrompt)=>{
        const newNotes=currentCompany.notes2.filter(n=>n.automationId===automation._id)
        if(newNotes.length===0){
            return alert("No new notes")
        }
        if(automation.headline===""){
            return alert("Choose a headline")
        }

        newNotes.forEach(n=>{
            currentCompany.notes.push(n)
        })
        currentCompany.notes2=currentCompany.notes2.filter(n=>!newNotes.includes(n))
        setDB([...DB])
    }

    const getPreview=async (automation:AutomationObjWebPrompt)=>{
        const body={
            url:automation.url,
            prompt:automation.prompt,
            selectors:automation.selectors.split(",").filter(n=>n!==""),
            multipleNotes:automation.multipleNotes
        }
        setLoading(true)
        const resObj= await axios.post(`http://localhost:6001/scrape`,body)
        const res:previewRes=resObj.data
        automation.originalText=res.originalText;
        if(res.data && automation.multipleNotes){
            for(const note of res.data){ 
                const newId=generateNewId(currentCompany.notes.map(n=>n._id)).toString()
                const newNote:note2={
                    title:note.title,
                    text: note.text,
                    isProduction: false,
                    _id: newId,
                    useLocation: false,
                    selectorsForInstantUpdate:null,
                    url: automation.url,
                    type: "webPrompt",
                    new:true,
                    automationId:automation._id,
                    isHeadline:false,
                    notes:[]
                }
                currentCompany.notes2.push(newNote)
            }
            setDB([...DB])
            
        }
        else if(res.title && !automation.multipleNotes){
            const newId=generateNewId(currentCompany.notes.map(n=>n._id)).toString()
            const newNote:note2={
                title:res.title,
                text: res.text||"",
                isProduction: false,
                _id: newId,
                useLocation: false,
                selectorsForInstantUpdate:null,
                url: automation.url,
                type: "webPrompt",
                new:true,
                automationId:automation._id,
                isHeadline:false,
                notes:[]
            }
            currentCompany.notes2.push(newNote)
        }
        setLoading(false)
    }


    
    const handleSelectChange = (automation: AutomationObjWebPrompt) => (e: React.ChangeEvent<HTMLSelectElement>) => {
        console.log(automation.headline,e.target.value)
        automation.headline=e.target.value;
        setDB([...DB])
    }

    return(
            <div className={styles.automationObj}>
                <RxCross2 onClick={()=>handleAutomationDelete(automation)}/>
                <div>Title</div>
                <input value={automation.title} onChange={handleValueChange(automation,"title")}/>
                <div>Url*</div>
                <input value={automation.url} onChange={handleValueChange(automation,"url")}/>
                <div>Prompt</div>
                <AutoExpandingTextarea myValue={automation.prompt} setValue={(value:string)=>handlePromptChange(automation,value)}/>
                <div>Selectors</div>
                <input value={automation.selectors} onChange={handleValueChange(automation,"selectors")}/>

                <div>Multiple notes</div>
                <input type="checkbox" checked={automation.multipleNotes} onChange={handleMultipleNotesChange(automation)} style={{width:"50px"}}/>

                {loading
                ?<div>Loading...</div>
                :<button style={{margin:"15px"}} onClick={()=>getPreview(automation)}>Load</button>
                }

                <button style={{margin:"15px"}} onClick={()=>setPreView(true)}> preview</button>

                <div>Update delay in minutes</div>
                <input placeholder='No text = never update' value={automation.updateIn===null?"":automation.updateIn?.toString()} onChange={handleUpdateInChange(automation)} />
                <div style={{marginTop:"5px"}}>headline</div>

                <div style={{display:"flex",gap:"5px",marginTop:"5px"}}>
{/*                     <select value={automation.headline} onChange={handleSelectChange(automation)}>
                        {currentCompany.headlines2.map((n,i)=>{
                            return <option key={i} value={n.name}>{n.name}</option>
                        })}
                    </select> */}
                </div>

                <div className={styles.setData} onClick={()=>setNewNotesToData(automation)}>Push</div>

            </div>
    )
}

export default AutomationObj
import React, { useState } from 'react'
import styles from './Automation2.module.css'
import { note2,AutomationObjWebPrompt, Company } from 'types'
import { IoIosAddCircleOutline } from 'react-icons/io'
import { RxCross2 } from "react-icons/rx";
import { generateNewId } from 'functions/id';
import AutoExpandingTextarea from 'components/AutoExpandingTextarea';
import AutomationObj from './components/AutomationObj';

interface IAutomation2{
    currentCompany:Company;
    DB:Array<Company>;
    setDB:(companies:Array<Company>)=>void;
}


const Automation2:React.FC<IAutomation2>=({currentCompany,DB,setDB})=>{
    const companyAutomations=currentCompany.automations
    const [preview,setPreView]=useState(false)
    
    const handleAdd=()=>{
        const newAutomationObj:AutomationObjWebPrompt={
            title:"",
            url:"",
            prompt:"",
            selectors:"",
            headline:"",
            notes:[],
            originalText:"",
            multipleNotes:false,
            updateIn:null,
            new:true,
            _id:generateNewId(currentCompany.automations.map(n=>n._id))
        }
        companyAutomations.unshift(newAutomationObj)
        setDB([...DB])
    }

    const setPreviewNoteText=(note:note2)=>{
        const index=currentCompany.notes.findIndex(n=>n._id===note._id)
        currentCompany.notes[index]=note
        setDB([...DB])
    }
    console.log(currentCompany.notes2)

    return(
        <div className={styles.container}>
            {preview &&
                <div className={styles.preview}>
                    <RxCross2 onClick={()=>setPreView(false)}/>
                    <div style={{height:"90%",width:"90%",padding:"5px",}}>
                        {currentCompany.notes2
                            .map(note=>{
                                return (
                                <div className={styles.notePreview}>
                                    <div>Title</div>
                                    <input value={note.title}/>
                                    <div>Text</div>
                                    <AutoExpandingTextarea myValue={note.text} setValue={setPreviewNoteText}/>
                                    <div>Use location?</div>
                                    <input type="checkbox" checked={note.useLocation}/>
                                    <div>Production?</div>
                                    <input type="checkbox" checked={note.isProduction}/>
                                </div>)
                            })}
                    </div>
                </div>
            }
            <div className={styles.content}>
                <IoIosAddCircleOutline onClick={()=>handleAdd()}/>
                {companyAutomations.filter(n=>!n.deleted).map(automation=>{
                    return <AutomationObj automation={automation} currentCompany={currentCompany} DB={DB} setDB={setDB} setPreView={setPreView}/>
                })}
            </div>

        </div>
    )

}

export default Automation2
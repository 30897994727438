import styles from './data.module.css'
import React, { useState } from 'react'
import AutoExpandingTextarea from './components/AutoExpandingTextarea'
import generateNewId from '../../functions/id/generateNewId'
import PopUp from '../../components/PopUp'
import { IoIosAddCircleOutline } from 'react-icons/io'
import { RiDeleteBin6Line } from 'react-icons/ri'

function Data({
  currentCompany,
  currentHeadLine,
  setCurrentHeadline,
  DB,
  setDB,
  DB_old,
}) {
  const staticCurrentCompany = DB_old.find(
    (company) => company._id === currentCompany._id
  )

  const currentStaticHeadline = staticCurrentCompany.headlines.find(
    (headline) => headline.id === currentHeadLine.id
  )

  const [headlinePopUp, setHeadlinePopUp] = useState(false)

  const handleHeadlinePopUp = () => {
    setHeadlinePopUp(!headlinePopUp)
  }

  const newHeadline = async (newHeadlineName) => {
    if (!newHeadlineName) {
      alert('Please enter a headline name')
      return
    } else if (
      currentCompany.headlines.map((n) => n.name).includes(newHeadlineName)
    ) {
      alert('duplicate name')
      return
    }
    try {
      let newID = generateNewId(currentCompany.headlines)
      DB.find((n) => n._id === currentCompany._id).headlines.push({
        name: newHeadlineName,
        notes: [],
        id: newID,
        headlineInfo: '',
        new: true,
      })
      setHeadlinePopUp(false)
      setDB([...DB])
    } catch (error) {
      console.error('Error creating headline:', error)
    }
  }
  console.log(currentCompany.headlines)
  const handleDeleteNote = async (note) => {
    if (note.isProduction) {
      alert('Cant delete production notes')
    } else {
      const isConfirmed = window.confirm(
        `Are you sure you want to delete note ${note.title}?`
      )
      if (isConfirmed) {
        let selected = currentHeadLine.notes.find((n) => n.id === note.id)
        if (selected) {
          if (selected.new) {
            currentHeadLine.notes = currentHeadLine.notes.filter(
              (n) => n.id !== note.id
            )
          } else {
            selected.deleted = true
          }
          setDB([...DB])
        }
      }
    }
  }

  const handleAddNote = async () => {
    if (currentHeadLine === '') {
      alert('Please select a headline')
      return
    } else {
      if (!currentHeadLine.isProduct) {
        try {
          let newID = generateNewId(
            currentCompany.headlines.map((n) => n.notes).flat()
          )
          currentHeadLine.notes.push({
            title: '',
            text: '',
            isProduction: false,
            id: newID,
            keyWords: '',
            location: '',
            new: true,
          })
          setDB([...DB])
        } catch (e) {}
      } else {
        try {
          let newID = generateNewId(
            currentCompany.headlines.map((n) => n.notes).flat()
          )
          let newLocalDB = [...DB]
          newLocalDB
            .find((n) => n._id === currentCompany._id)
            .headlines.find((n) => n.name === currentHeadLine.name)
            .notes.push({
              name: '',
              price: '',
              id: newID,
              description: '',
              url: '',
              link: '',
              tag: '',
              new: true,
            })
          setDB(DB)
        } catch (e) {}
      }
    }
  }
  const buttonHoverStyle={
    backgroundColor: '#041f3e',
    color: 'white',
  }

  const handleHeadlineDelete = async () => {
    const headline = currentHeadLine
    if (headline.notes.map((n) => n.isProduction).includes(true)) {
      alert('Cant delete headline with production notes')
    } else {
      const isConfirmed = window.confirm(
        `Are you sure you want to delete headline ${headline.name}?`
      )
      if (isConfirmed) {
        setCurrentHeadline('')
        let selected = currentCompany.headlines.find(
          (n) => n.id === headline.id
        )
        if (selected) {
          if (selected.new) {
            currentCompany.headlines = currentCompany.headlines.filter(
              (n) => n.id !== headline.id
            )
          } else {
            selected.deleted = true
          }
          const left = currentCompany.headlines.filter((n) => !n.deleted)
          if (left.length > 0) {
            setCurrentHeadline(left[0])
          }
          setDB([...DB])
        }
      }
    }
  }

  const onHeadlineInfoChange = (e) => {
    currentHeadLine.headlineInfo = e.target.value
    setDB([...DB])
  }

  const onNoteChange = (note, field) => (e) => {
    note[field] = e.target.value
    setDB([...DB])
  }

  const handleHeadlineNameChange=(e)=>{
    console.log( currentCompany.headlines.find(n=>currentHeadLine.name===n.name))
    currentCompany.headlines.find(n=>currentHeadLine.name===n.name).name=e.target.value
    setDB([...DB])
  }

  if (currentCompany) {
    return (
      <div className={styles.container2}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100px',
            alignItems: 'flex-end',
          }}>
          <div
            style={{
              display: 'flex',
              marginTop: '23px',
              gap: '5px',
              marginBottom: '30px',
            }}>
            <RiDeleteBin6Line
              className={styles.icon}
              onClick={() => handleHeadlineDelete()}
            />
            <IoIosAddCircleOutline
              className={styles.icon}
              onClick={() => handleHeadlinePopUp()}
            />
          </div>

          <IoIosAddCircleOutline
            className={styles.icon}
            onClick={() => handleAddNote()}
          />
        </div>

        <div className={styles.container}>
          <div className={styles.headLineContainer}>
            {currentCompany.headlines
              .filter((n) => !n.deleted)
              .map((headLine, i) => {
                return (
                  <div className={styles.headLineHolder} key={headLine.id}>
                    {currentHeadLine ===headLine
                    ?<div className={styles.headlineInputButton} style={buttonHoverStyle}>
                      <input value={headLine.name} onChange={handleHeadlineNameChange}/>
                      </div>
                    :<button
                      className={styles.dataButton}
                      onClick={() => setCurrentHeadline(headLine)}>
                      {headLine.name}
                    </button>
                    }
                  </div>
                )
              })}
          </div>

          {headlinePopUp && (
            <PopUp
              text={'New headline for ' + currentCompany.name}
              setPopUp={setHeadlinePopUp}
              save={newHeadline}
            />
          )}

            {currentHeadLine && (
              <div>
                <AutoExpandingTextarea
                  myText={currentHeadLine.headlineInfo}
                  change={onHeadlineInfoChange}
                  redBorder={
                    currentHeadLine.new ||
                    currentHeadLine.headlineInfo !==
                      currentStaticHeadline.headlineInfo
                  }
                />
              </div>
            )}

            {currentHeadLine &&
              currentCompany.headlines
                .find((headLine) => headLine.name === currentHeadLine.name)
                .notes.filter((n) => !n.deleted)
                .map((note, i) => {
                  let staticNote = currentStaticHeadline?.notes.find(
                    (n) => n.id === note.id
                  )
                  return (
                    <div className={styles.noteContainer} key={note.id}>
                      <div className={styles.notesBox}>
                        Title
                        <input
                          value={note.title}
                          onChange={onNoteChange(note, 'title')}
                          className={styles.notesTitle}
                          style={
                            !staticNote || staticNote.title !== note.title
                              ? { borderColor: 'red' }
                              : {}
                          }
                        />
                        Key words
                        <input
                          value={note.keyWords ? note.keyWords : ''}
                          onChange={onNoteChange(note, 'keyWords')}
                          className={styles.notesTitle}
                          style={
                            !staticNote || staticNote.keyWords !== note.keyWords
                              ? { borderColor: 'red' }
                              : {}
                          }
                        />
                        Pathname
                        <input
                          value={note.location ? note.location : ''}
                          onChange={onNoteChange(note, 'location')}
                          className={styles.notesTitle}
                          style={
                            !staticNote || staticNote.location !== note.location
                              ? { borderColor: 'red' }
                              : {}
                          }
                        />
                        <AutoExpandingTextarea
                          myText={note.text}
                          change={onNoteChange(note, 'text')}
                          redBorder={
                            !staticNote || staticNote.text !== note.text
                          }
                        />
                        {note.automation !== undefined &&
                          Object.keys(note.automation).map((n, i) => {
                            return (
                              <div
                                key={i}
                                style={{
                                  padding: '20px',
                                  width: '90%',
                                  margin: '10px',
                                  border: '1px solid black',
                                }}>
                                <div>{n}:</div>
                                <div>{note.automation[n]}</div>
                              </div>
                            )
                          })}
                      </div>

                      <div className={styles.FinalHolder}>
                        <RiDeleteBin6Line
                          onClick={() => handleDeleteNote(note)}
                        />
                        {/*                                 <div onClick={()=>(note)} className='prodBox' style={note.isProduction ?{backgroundColor:"green"}:{}}></div> */}
                      </div>
                    </div>
                  )
                })}
          </div>
      </div>
    )
  }
}

export default Data

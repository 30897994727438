import axios from 'axios'
const url = 'http://localhost:6001'
const url2 = 'https://emeric-back-z573vwoula-uc.a.run.app'



export const moreLinks=async(url)=>{
  return axios.post(`${url}/moreLinks`,{url})
}

export const getWebsites=async()=>{
  return axios.get(`${url}/websites`)
}

export const getUrlObjs = async (body) => {
  const res=await axios.post(`${url}/urlObjs`,body)
  return res.data
}


export const updateUrlObj = async (body) => {
  return axios.put(`${url}/oneUrl`,body)
}


export const getUrlObject = async (body) => {
  return axios.post(`${url}/agi`,body)
}



export const savePromptObj=async(body)=>{
  return axios.post(`${url}/companies/promptObject`,body)
}
export const getPromptObj=async()=>{
  return axios.get(`${url}/companies/promptObject`)
}

export const saveAutomationObj=async(body)=>{
  return axios.post(`${url}/automationobj`,body)
}

export const saveHeadlines2= async(body,id)=>{
  return axios.post(`${url}/companies/${id}/headlines2`,body)
}
export const saveMultipleNotes= async(body,id)=>{
  return axios.post(`${url}/companies/${id}/note`,body)
}
export const deleteNote= async(body,id)=>{
  return axios.put(`${url}/companies/${id}/note/delete`,body)
}
export const putNotes= async(body,id)=>{
  return axios.put(`${url}/companies/${id}/note`,body)
}

export const getDemoList = async () => {
  return axios.get(`${url}/demoList`)
}

export const handleDataScrape=async(body)=>{
  return axios.post(`${url}/scrape`,body)
}

export const getCompanyShorts = async (companyName) => {
  return axios.get(`${url}/companyName/${companyName}`)
}

export const postCompany = async (body) => {
  return axios.post(`${url}/companies`, body)
}

export const deleteCompany = async (name) => {
  return axios.delete(`${url}/companies/${name}`)
}



export const saveNotes = async (body, id) => {
  return axios.put(`${url}/companies/${id}/allNotes`, body)
}


export const getAllCompanyNames = async () => {
  return axios.get(`${url}/companyNames`)
}


export const testURL = async (body, id) => {
  return axios.post(`${url}/companies/${id}/urls`, body)
}

export const testScraping = async (body) => {
  return axios.post(`${url}/scraping/test`, body)
}

export const postScraping = async (body) => {
  return axios.post(`${url}/scraping/add`, body)
}
export const saveAll = async (body, id) => {
  return axios.put(`${url}/saveAll/${id}`, { data: JSON.stringify(body) })
}

export const deleteChats = async (body, id) => {
  return axios.put(`${url}/chats/${id}`,  body)
}

export const selectChats=async(body,id)=>{
  return axios.put(`${url}/chats/example/${id}`, body)
}

export const saveLogic = async(body,id)=>{
  return axios.put(`${url}/logic/${id}`, body)
}

export const handleChangePassword= async(body,id)=>{
  return axios.post(`${url}/companies/${id}/password`, body)
}

export const getWebsiteCaches= async()=>{
  return axios.get(`${url}/companies/websiteCache`)
}

//await axios.post(`http://localhost:5000/companies/${currentCompany._id}/allDragnotes`,{dragNotes:company.dragNotes})

//let res2= await axios.post(`http://localhost:5000/qdrant/delete`,{collection:company.name});

//let res1 = await axios.delete(`http://localhost:5000/companies/${company._id}`);
//let res2= await axios.post(`http://localhost:5000/qdrant/delete`,{collection:company.name});

//await axios.post('http://localhost:5000/qdrant/create', { collection:newCompanyName.toLocaleLowerCase().replaceAll(" ","-") })

import { RxCross2 } from "react-icons/rx";
import { Iurl, urlChild, urlObject } from "types";
import styles from "./pathnameMenu.module.css"

interface IPathnameMenu {
    obj: Iurl;
    linkList: Array<Iurl>;
    reRender: () => void;
    showDeleted:boolean;
}



const PathnameMenu: React.FC<IPathnameMenu> = ({ obj,linkList, reRender,showDeleted }) => {
    const handleUrlSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!obj.headlineId) {
            if (obj.selected) {
                obj.selected=false
            }
            else {
                obj.selected = true;
            }
            reRender()
        }
    }

    const handleDelete = () => {
        if(obj.deleted){
            obj.deleted=false
        }
        else{
            obj.deleted=true
        }
        reRender()
    }

    const handleHeadlineSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const value=e.target.value
        
        obj.headlineId =value

        for(const note of linkList){
            if(note.url.startsWith(obj.url) && obj.url!==note.url && !note.deleted && note.data){
                note.selected=value==="-"?false:true
                note.parentId=value
            }
        }
        reRender()
    }



    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (obj.headlineId) {
            obj.headlineId = ""
        }
        else {
            obj.headlineId = "-"
        }
        reRender()
    }



    const handleWholePage = () => {
        if (obj.wholePage) {
            obj.wholePage = false
        }
        else {
            obj.wholePage = true
        }
        reRender()
    }


    //const allChildAreWholePage=linkList.filter(n=>n.url.startsWith(obj.url)  &&  n.url!==obj.url && !n.deleted).every(n=>n.wholePage)
    
    const handleAllToOne=(e:React.ChangeEvent<HTMLInputElement>)=>{
/*         const myBool=allChildAreWholePage?false:true
        for(const note of linkList){
            if(note.url.startsWith(obj.url) &&  obj.url!==note.url && !note.deleted){
                note.wholePage=myBool
            }
        }
        reRender() */

    }

    const isSelected = (!obj.deleted && obj.selected) ? true : false

    const handleParentSelect=(e:React.ChangeEvent<HTMLSelectElement>)=>{
        obj.parentId=e.target.value
        reRender()
    }

    const pathname=obj.pathname
    const pahtnameSplitted=pathname.split("/").filter(Boolean)
    
    function checkMaxLength(newpath:string){
        const splitted=newpath.split("/").filter(Boolean)
        let res=0
        for(const path of pahtnameSplitted){
            const index=splitted.indexOf(path)
            if(index!==-1){
                if(index===0 && pahtnameSplitted.length==1){
                }
                else{
                    if(splitted[index]===path && linkList.filter(n=>n.pathname.startsWith("/"+pahtnameSplitted.slice(0,index+1).join("/")) && !n.deleted ).length>1){
                        res++
                    }
                    else{
                        break
                    }  
                }

            }
            else{
                break
            }
        }
        return res
    }

    const longestSharedPath = linkList.reduce((prev, curr) => {
        if (checkMaxLength(curr.pathname) > checkMaxLength(prev.pathname)) {
            return curr;
        }
        return prev;
    }, {pathname:"/"})

    const sharedPath=checkMaxLength(longestSharedPath.pathname)

    console.log(linkList)

    return (
        <>
            <div className={styles.container} style={{marginLeft:`${30*(sharedPath)}`}}>
                <div style={{display: "flex", gap: "5px", padding: "1px" }}>
                    {obj.data && <input type='checkbox' checked={isSelected} onChange={handleUrlSelect} />}
                    
{/*                     {isSelected && 
                    <select value={urlObj.headlines.find(n=>n.name===obj.parentId)?.name ||"-"} onChange={handleParentSelect}>
                        {options.map((n, i) => {
                            return <option key={i} value={n.name}>{n.name}</option>
                            })
                        }
                    </select>
                    } */}

                    {isSelected && obj.data &&
                        <div style={{ display: "flex" }}>
                            <div>whole page?</div>
                            <input type="checkbox" checked={obj.wholePage ? true : false} onChange={handleWholePage} />
                        </div>
                    }
                    {obj.data
                        ? <a href={obj.url} target='_blank' rel="noreferrer" className={styles.pathname}>{pathname.slice(0, pathname.length)}</a>
                        : <div className={styles.pathname}>{pathname.slice(0, pathname.length)}</div>
                    }
                    {
                        obj.headlineId &&
                        <div style={{display:"flex",gap:"5px",alignItems:"center"}}>
                            <input type='checkbox' checked={obj.headlineId ? true : false} onChange={handleChange} />
                            {obj.headlineId &&
                                <div className={styles.selectHolder}>
{/*                                     <select value={urlObj.headlines.find(n=>n.name===obj.headlineId)?.name ||"-"} onChange={handleHeadlineSelect}>
                                        {options.map((n, i) => {
                                            return <option key={i} value={n.name}>{n.name}</option>
                                        })
                                        }
                                    </select> */}
                                    All childs to one page?
                                    <input type="checkbox" checked={false} onChange={handleAllToOne}/>
                                </div>
                            }
                        </div>
                    }
                    <RxCross2 style={{ marginLeft: "auto" }} onClick={() => handleDelete()} />
                </div>
            </div>
        </>
    )
}

export default PathnameMenu
import React, { useEffect, useRef } from 'react'
import styles from './AutoExpandingTextarea.module.css'


function AutoExpandingTextarea({ myValue, setValue }) {
  const textareaRef = useRef(null)

  const adjustHeight = () => {
    const textarea = textareaRef.current
    if (textarea) {
      textarea.style.height = 'auto'
      textarea.style.height = `${textarea.scrollHeight + 10}px`
    }
  }

  useEffect(() => {
    adjustHeight()
  }, [myValue])

  const handleChange = (event) => {
    const textarea = event.target
    setValue(textarea.value)
    adjustHeight()
  }

  return (
    <textarea
      className={styles.MyNoteMainText}
      value={myValue}
      onChange={handleChange}
      ref={textareaRef}
      style={{ overflow: 'hidden', resize: 'none' }}
    />
  )
}

export default AutoExpandingTextarea

const Test = ({ DB, setDB, currentCompany }) => {
  const handleAddTest = () => {
    currentCompany.tests.push({ question: '', expected: [], modified: 'new' })
    setDB([...DB])
  }

  const handleQuestionChange = (test) => (e) => {
    if (test.modified === 'new') {
    } else if (test.modified === undefined) {
      test.modified = { question: test.question }
    } else if (test.modified.question === e.target.value) {
      test.modified.question = undefined
    }

    test.question = e.target.value

    setDB([...DB])
  }

  const handleRemove = (i) => {
    currentCompany.tests = currentCompany.tests.filter((_, j) => i !== j)
    setDB([...DB])
  }

  if (currentCompany === '') {
    return <div style={{ height: '1000px' }}></div>
  } else {
    return (
      <div
        style={{
          width: '100%',
          minHeight: '600px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'start',
          alignItems: 'center',
          gap: '20px',
        }}>
        <div>
          <button style={{ width: '100px' }}>save</button>
          <button style={{ width: '100px' }}>run test</button>
        </div>
        <div
          style={{
            width: '90%',
            display: 'flex',
            flexDirection: 'column',
            gap: '20px',
            justifyContent: 'start',
            paddingTop: '20px',
          }}>
          {currentCompany.tests.map((test, i) => {
            return (
              <div
                key={i}
                style={{ display: 'flex', flexDirection: 'row', gap: '30px' }}>
                <div>
                  <span>question:</span>
                  <input
                    value={test.question}
                    onChange={handleQuestionChange(test)}
                  />
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '10px',
                  }}>
                  <span>target 1</span>
                  <select style={{ maxWidth: '250px' }}>
                    {currentCompany.headlines
                      .map((n) => n.notes)
                      .flat()
                      .map((note, i) => {
                        return (
                          <option key={i} value={note}>
                            {' '}
                            {`${note.title}/${note.keyWords}`}{' '}
                          </option>
                        )
                      })}
                  </select>

                  <span>target 2</span>

                  <select style={{ maxWidth: '250px' }}>
                    {currentCompany.headlines
                      .map((n) => n.notes)
                      .flat()
                      .map((note, i) => {
                        return (
                          <option key={i} value={note}>
                            {' '}
                            {`${note.title}/${note.keyWords}`}{' '}
                          </option>
                        )
                      })}
                  </select>

                  <span>target 3</span>

                  <select style={{ maxWidth: '250px' }}>
                    {currentCompany.headlines
                      .map((n) => n.notes)
                      .flat()
                      .map((note, i) => {
                        return (
                          <option key={i} value={note}>
                            {' '}
                            {`${note.title}/${note.keyWords}`}{' '}
                          </option>
                        )
                      })}
                  </select>
                </div>
                <button onClick={() => handleRemove(i)}>x</button>
              </div>
            )
          })}
        </div>

        <button onClick={() => handleAddTest()}>add</button>
        <div>
          <span>Previous tests:</span>
          <div>
            <div>question amount: 10</div>
            <div>score: 10/30</div>
            <div>target data amount: 50</div>
            <div>target data hits:20</div>
            <div>target data missed:30</div>
          </div>
        </div>
      </div>
    )
  }
}

export default Test

import React, { useState } from 'react'
import styles from './ScrapeSection.module.css'
import FetchComponent from '../FetchComponent'
import json from './output.json'
import { testScraping } from '../../../../api'
function isNumber(value) {
  return typeof value === 'number' && isFinite(value)
}

const ScrapeSection = ({ currentCompany, urls }) => {
  const [selectedHeadline, setSelectedHeadline] = useState('-')
  const [option, setOption] = useState(0)
  const [updateTime, SetUpdateTime] = useState('')
  const [options, setOptions] = useState([])
  const [keywordOptions, setKeywordOptions] = useState([])
  const [titleOptions, setTitleOptions] = useState([])
  const [textOptions, setTextOptions] = useState([])
  const [staticText, setStaticText] = useState({
    title: '',
    keywords: '',
    text: '',
    name: '',
  })

  let [selectedDict, setSelectedDict] = useState({
    title: [],
    keyWords: [],
    text: [],
  })

  const [location, setLocation] = useState(false)

  const [selectedSelect, setSelectedSelect] = useState(null)

  const handleSelectChange = (event) => {
    const cur = currentCompany.headlines.find(
      (n) => n.name === event.target.value
    )
    setSelectedHeadline(cur ? cur : '-')
  }

  const handleOptionChange = (num) => {
    setOption(num)
  }

  const handleUpdateChange = (e) => {
    let val = e.target.value
    let num = Number(val)

    if (isNumber(num) || val === '') {
      SetUpdateTime(val)
    }
  }

  const handleStaticChange = (field) => (e) => {
    staticText[field] = e.target.value
    setStaticText({ ...staticText })
  }

  const formattedJSON = JSON.stringify(json, null, 2)

  document.addEventListener('mouseup', () => {
    const selection = window.getSelection().toString()
    if (selection.length > 0) {
      let newJson = json
      console.log(selectedSelect)
      if (selectedSelect) {
        for (let sel of selectedDict[selectedSelect]) {
          newJson = newJson[sel]
        }
        if (Object.keys(newJson).includes(selection)) {
          selectedDict[selectedSelect].push(selection)
          setSelectedDict({ ...selectedDict })
        }
      }
    }
  })

  const handleRemove = (field) => {
    selectedDict[field] = selectedDict[field].filter(
      (_, i) => i !== selectedDict[field].length - 1
    )
    setSelectedDict({ ...selectedDict })
  }

  const handleChange = (field) => (e) => {
    setSelectedSelect(field)
  }

  const [mouseY, setMouseY] = useState(0)

  const handleMouseMove = (event) => {
    const { top } = event.currentTarget.getBoundingClientRect()
    setMouseY(event.clientY - top)
    console.log(mouseY)
  }

  const handleTitleAdd = () => {
    titleOptions.push([])
    setTitleOptions([...titleOptions])
  }

  const handleTitleDelete = (i) => {
    setTitleOptions([...titleOptions.filter((_, index) => i !== index)])
  }
  const handleKeywordDelete = (i) => {
    setKeywordOptions([...keywordOptions.filter((_, index) => i !== index)])
  }

  const handleKeywordAdd = () => {
    keywordOptions.push([''])
    setKeywordOptions([...keywordOptions])
  }

  const handleChangeKeywordOptions = (newOptions, i) => {
    // You need to update the state in an immutable way
    const newKeyWordOptions = [...keywordOptions]
    newKeyWordOptions[i] = newOptions
    setKeywordOptions(newKeyWordOptions)
  }

  const handleChangeTestOptions = (newOptions, i) => {
    // You need to update the state in an immutable way
    const newTextOption = [...textOptions]
    newTextOption[i] = newOptions
    setTextOptions(newTextOption)
  }

  const handleTextAdd = () => {
    textOptions.push([''])
    setTextOptions([...textOptions])
  }
  const handleTextDelete = (i) => {
    setTextOptions([...textOptions.filter((_, index) => i !== index)])
  }

  const testScrape = async () => {
    const body = {
      updateTime: updateTime,
      headlineId: selectedHeadline.id,
      type: 'fetch',
      name: staticText.name,
      first: options,
      title: { options: titleOptions, text: staticText.title },
      keyWords: { options: keywordOptions, text: staticText.keywords },
      text: { options: textOptions, text: staticText.text },
      location: location,
      urls: urls,
    }
    console.log(body)
    //await testScraping(body)
  }

  return (
    <div className={styles.container}>
      <div>
        <span>Update in minutes: </span>
        <input value={updateTime} onChange={handleUpdateChange} />
        <span>m</span>
      </div>

      <div className={styles.headline}>
        <div>Select headline: </div>
        <select style={{ maxWidth: '250px' }} onChange={handleSelectChange}>
          {[{ name: '-' }]
            .concat(currentCompany.headlines)
            .map((headline, i) => {
              return (
                <option key={i} value={headline.name}>
                  {' '}
                  {headline.name}{' '}
                </option>
              )
            })}
        </select>
      </div>
      <div className={styles.options}>
        <div
          style={option === 0 ? { color: 'green' } : {}}
          onClick={() => handleOptionChange(0)}>
          Fetch
        </div>
        <div
          style={option === 1 ? { color: 'green' } : {}}
          onClick={() => handleOptionChange(1)}>
          Scrape
        </div>
        <div
          style={option === 2 ? { color: 'green' } : {}}
          onClick={() => handleOptionChange(2)}>
          JSON
        </div>
      </div>

      {option === 0 && (
        <div className={styles.fetch}>
          <div className={styles.oneSection}>
            <div>Name:</div>
            <input
              value={staticText.name}
              onChange={handleStaticChange('name')}
            />
          </div>

          <div className={styles.oneSection}>
            <span>First</span>
            <FetchComponent options={options} setOptions={setOptions} />
          </div>

          <div className={styles.oneSection}>
            <div>Title:</div>
            <input
              value={staticText.title}
              onChange={handleStaticChange('title')}
            />
            {titleOptions.map((n, i) => {
              return (
                <div>
                  <FetchComponent
                    options={n}
                    setOptions={(newOptions) => {
                      // You need to update the state in an immutable way
                      const newTitleOptions = [...titleOptions]
                      newTitleOptions[i] = newOptions
                      setTitleOptions(newTitleOptions)
                    }}
                  />
                  <button onClick={() => handleTitleDelete(i)}>x</button>
                </div>
              )
            })}
            <button onClick={() => handleTitleAdd()}>add</button>
          </div>

          <div className={styles.oneSection}>
            <div>Keywords:</div>
            <input
              value={staticText.keywords}
              onChange={handleStaticChange('keywords')}
            />
            {keywordOptions.map((n, i) => {
              return (
                <div>
                  <FetchComponent
                    options={n}
                    setOptions={(newOptions) =>
                      handleChangeKeywordOptions(newOptions, i)
                    }
                  />
                  <button onClick={() => handleKeywordDelete(i)}>x</button>
                </div>
              )
            })}
            <button onClick={() => handleKeywordAdd()}>add</button>
          </div>

          <div className={styles.oneSection}>
            <div>Text:</div>
            <input
              value={staticText.text}
              onChange={handleStaticChange('text')}
            />
            {textOptions.map((n, i) => {
              return (
                <div>
                  <FetchComponent
                    options={n}
                    setOptions={(newOptions) =>
                      handleChangeTestOptions(newOptions, i)
                    }
                  />

                  <button onClick={() => handleTextDelete(i)}>x</button>
                </div>
              )
            })}
            <button onClick={() => handleTextAdd()}>add</button>
          </div>

          <div>Include location</div>
          <input
            type='checkbox'
            checked={location}
            onChange={(e) => setLocation(!location)}
          />
          <button onClick={() => testScrape()}>test</button>
        </div>
      )}
      {option === 2 && (
        <div className={styles.jsonContainer}>
          <div
            style={{ display: 'flex', gap: '15px', flexDirection: 'column' }}>
            <div style={{ display: 'flex', gap: '15px' }}>
              <input
                type='checkbox'
                onChange={handleChange('text')}
                checked={selectedSelect === 'text'}
              />
              <div>text: </div>
              <div>{JSON.stringify(selectedDict.text)}</div>
              <button onClick={() => handleRemove('text')}>x</button>
            </div>

            <div style={{ display: 'flex', gap: '15px' }}>
              <input
                type='checkbox'
                onChange={handleChange('keyWords')}
                checked={selectedSelect === 'keyWords'}
              />
              <div>keyWords: </div>
              <div>{JSON.stringify(selectedDict.keyWords)}</div>
              <button onClick={() => handleRemove('keyWords')}>x</button>
            </div>

            <div style={{ display: 'flex', gap: '15px' }}>
              <input
                type='checkbox'
                onChange={handleChange('title')}
                checked={selectedSelect === 'title'}
              />
              <div>title: </div>
              <div>{JSON.stringify(selectedDict.title)}</div>
              <button onClick={() => handleRemove('title')}>x</button>
            </div>
          </div>

          <div className={styles.json}>
            <pre onMouseMove={handleMouseMove}>
              <code>{formattedJSON}</code>
            </pre>
          </div>
        </div>
      )}
    </div>
  )
}

export default ScrapeSection

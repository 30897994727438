import React, { useState } from 'react'
import { VscTriangleDown } from 'react-icons/vsc'
import { VscTriangleUp } from 'react-icons/vsc'
import styles from './ChatHolder.module.css'
import { RiDeleteBin6Line } from 'react-icons/ri'
import { deleteChats,selectChats } from '../../../api'
import { IoIosAddCircleOutline } from 'react-icons/io'
import { FaRegCheckCircle } from "react-icons/fa";


function isSameDay(date1, date2) {
  return (
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate()
  )
}

const ChatHolder = ({ chats,currentCompany,setChats }) => {
  const [selectedChat, setSelectedChat] = useState(false)
  const [filteredDates, setFilteredDates] = useState([])
  const [showDemoChats, setShowDemoChats] = useState(false)


  let today = new Date()

  function handleFilterClick(date) {
    if (filteredDates.includes(date)) {
      setFilteredDates((prev) => prev.filter((n) => n !== date))
    } else {
      setFilteredDates((prev) => prev.concat(date))
    }
  }

  function handleChatClick(chat) {
    if (selectedChat === chat) {
      setSelectedChat(false)
    } else {
      setSelectedChat(chat)
    }
  }

  const handleChatDeleteChange=(id)=>{
      let chat=chats.find(n=>n.id===id)
      if(chat){
        if(chat.deleted){
          delete chat.deleted
        }
        else{
          if(chat.selecting){
            delete chat.selecting
          }
          

          chat.deleted=true;
        }
        setChats([...chats])
      }
  }





const handleChatSelectChange=(id)=>(e)=>{
  let chat=chats.find(n=>n.id===id)
  if(chat){
    if(chat.deleted){
      return
    }
    if(chat.selected){
      if(chat.selected !== "delete"){
        chat.selected="delete"
      }
      else{
        chat.selected=true
      }
    }
    else{
      if(chat.selecting){
        delete chat.selecting
      }
      else{
        chat.selecting=true;
      }
    }

    setChats([...chats])
  }
}





  const handleChatDelete=async ()=>{
    try{
      let deleting=chats.filter(n=>n.deleted).map(n=>n.id)
      if(deleting.length>0){
        setSelectedChat(false)
        const res=await deleteChats({chats:deleting},currentCompany._id)
        if(res.data.result){
          chats=chats.filter(n=>!n.deleted)
          setChats(chats)
        }
      } 
    }
    catch(e){
      console.log(e)
    }
  }

  const handleChatSelect=async ()=>{
    try{
      let selecting=chats.filter(n=>(n.selecting || n.selected) && n.selected!=="delete" )
      if(selecting.length>0){
        setSelectedChat(false)
        const res=await selectChats({chats:selecting.map(n=>n.id)},currentCompany._id)
        console.log(res)
        if(res.data.result){
          chats=chats.map(n=>selecting.includes(n)?{...n,selected:true,selecting:undefined}:{...n,selected:false,selecting:undefined})
          setChats(chats)
        }
      } 
    }
    catch(e){
      console.log(e)
    }
  }


  let chatDict = {}
  chats
    .filter((n) => showDemoChats || !n.local)
    .slice()
    .reverse()
    .forEach((n, i) => {
      let date = n.id.split('T')[0]
      if (!chatDict[date]) {
        chatDict[date] = { chats: [n] }
      } else {
        chatDict[date].chats.push(n)
      }
    })

  const handleDemoChange = (e) => {
    setShowDemoChats(e.target.checked)
  }

  return (
    <div className={styles.container}>
      <div>
        <div className={styles.demoBox}>
          <div style={{display:"flexs"}}>
            <span>Näytä demo chatit</span>
            <input
              type='checkbox'
              checked={showDemoChats}
              onChange={handleDemoChange}
            />
          </div>

          {chats.find(n=>n.deleted) && 
            <div className={styles.selected}>
              <span>Delete {chats.filter(n=>n.deleted).length} chats</span><br/>
              {chats.filter(n=>n.deleted && n.select).length>0 &&
                  <span>From these {chats.filter(n=>n.deleted && n.select).length} are selected examples</span>
              }
              <RiDeleteBin6Line onClick={()=>handleChatDelete()}/>
            </div>
            }

          {chats.find(n=>n.selecting) && 
            <div className={styles.selected}>
              <span>Set {chats.filter(n=>n.selecting).length} example chats</span>
              <IoIosAddCircleOutline onClick={()=>handleChatSelect()}/>
            </div>
            }

          {chats.find(n=>n.selected==="delete") && 
            <div className={styles.selected}>
              <span>Unset {chats.filter(n=>n.selected==="delete").length} example chats</span>
              <IoIosAddCircleOutline onClick={()=>handleChatSelect()}/>
            </div>
            }

        </div>


        <div className={styles.chatList}>
          {Object.keys(chatDict).map((n,index) => {
            let curObject = chatDict[n]
            let curChats = curObject.chats
            let dd
            let objDate = new Date(n)
            if (isSameDay(today, objDate)) {
              dd = 'Tänään'
            } else if (isSameDay(new Date(today - 86400000), objDate)) {
              dd = 'Eilen'
            } else {
              dd = n.slice(5, n.length)
            }
            return (
              <div key={index}>
                  <div className={styles.dateContainer}>
                    <span className={styles.text}>{dd}</span>
                    {filteredDates.includes(n) ? (
                      <VscTriangleDown
                        className={styles.dateButton}
                        onClick={() => handleFilterClick(n)}
                      />
                    ) : (
                      <VscTriangleUp
                        className={styles.dateButton}
                        onClick={() => handleFilterClick(n)}
                      />
                    )}
                  </div>
                  <div
                    className={styles.oneDayChats}
                    style={
                      !filteredDates.includes(n) ? { display: 'none' } : {}
                    }>
                    {curChats.map((chat) => {
                      if (filteredDates.includes(chat.date)) {
                        return null
                      } else {
                        return (
                          <div key={chat.id} className={styles.oneChatContainer}>
                            <input type="checkbox" checked={(chat.selecting || (chat.selected===true))
                            && !chat.deleted} onChange={handleChatSelectChange(chat.id)}/>
                            <div
                              className={styles.oneChat}
                              onClick={() => handleChatClick(chat)}>
                              {`${chat.chats.filter(n=>n.role==="user").length} user messages ${chat.id.split('Z')[0].split('T')[1].slice(0, 5)}`}
                            </div>
                            {chat.selected && <FaRegCheckCircle/>}
                            <RiDeleteBin6Line style={chat.deleted?{color:"green",cursor:"pointer"}:{cursor:"pointer"}}  onClick={()=>handleChatDeleteChange(chat.id)}/>
                          </div>
                        )
                      }
                    })}
                  </div>
                </div>
            )
          })}
        </div>
      </div>
      {selectedChat && (
        <div className={styles.currentChat}>
          {chats
            .find((n) => n.id === selectedChat.id)
            .chats.map((n, i) => {
              const style=n.feedback===true?{border:"1px solid green"}:(n.feedback===false?{border:"1px solid red"}:{})
              return (
                <div className={styles[`oneChat${n.role}`]} key={i}>
                  <div style={style} className={styles.chatFinal}>{n.content}</div>
                </div>
              )
            })}
        </div>
      )}
    </div>
  )
}

export default ChatHolder

import saveOneCompany from '../../functions/saveOneCompany'
import styles from './SaveButton.module.css'
import React, { useState } from 'react'
import getOneCompanyCount from '../../functions/getOneCompanyCount'
import { FaRegSave } from "react-icons/fa";
import Save from '../Save';
import { savePromptObj } from 'api';



const SaveButton = ({ currentCompany,DB, DB_old, renderDBs,promptObject,promptObject_old,setPromptObject_old }) => {
  const [isHover,setIsHover]=useState(false)

  async function handleSavePromptObject(){
    if(JSON.stringify(promptObject)!==JSON.stringify(promptObject_old) ){
      const newObj={}
      for(let key of Object.keys(promptObject)){
  
        if(key!=="_id" && promptObject[key]!== promptObject_old[promptObject_old]){
          newObj[key]=promptObject[key]
        }
      }
      await savePromptObj(newObj)
      setPromptObject_old(JSON.parse(JSON.stringify(promptObject)))
    }
  }


  const handleSave = async () => {
    await handleSavePromptObject()

    if(currentCompany){
      const company_old = DB_old.find((n) => n._id === currentCompany._id)
      await saveOneCompany(currentCompany, company_old)
    }

    renderDBs()
  }

  let count=[]
  count=getOneCompanyCount(currentCompany?.name,DB,DB_old,promptObject,promptObject_old)
  console.log(count)
  return (
    <button onClick={() => handleSave()} className={styles.button} onMouseEnter={()=>setIsHover(true)}
    onMouseLeave={()=>setIsHover(false)}>
      <FaRegSave/>
      {count.length>0 && <span className={styles.redCount}>{count.length}</span>}
      {(isHover && count.length>0) && <Save text={count.join("\n\n ")}/>}
    </button>
  )
}

export default SaveButton

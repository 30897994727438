import generateRandomId from './generateRandomId'

function generateNewId(items) {
  let newID
  let bool = true
  while (bool) {
    newID = generateRandomId()
    if (items.find((n) => n.id === newID) === undefined) {
      bool = false
    }
  }
  return newID
}

export default generateNewId

import Draggable from 'react-draggable'
import AutoExpandingTextarea from '../../../../components/AutoExpandingTextarea'
import styles from './MyNote.module.css'
import React, { useRef } from 'react'
import { RiDeleteBin6Line } from 'react-icons/ri'
import { IoIosAddCircleOutline } from 'react-icons/io'


function generateRandomId() {
  const min = 1000000
  const max = 9999999
  return Math.floor(Math.random() * (max - min + 1)) + min
}

const connectedStyle = {
  backgroundColor: 'green',
}

function generateNewId(items) {
  let newID
  let bool = true
  while (bool) {
    newID = generateRandomId()
    if (items.find((n) => n.id === newID) === undefined) {
      bool = false
    }
  }
  return newID
}

const allowedMarks = ["","?","%","€","$","@","&"]

const MyNote = ({
  handleDeleteDragNote,
  currentCompany,
  currentConnection,
  handleDrag,
  setDB,
  DB,
  logicObj,
  handleTitleClick,
  handleTextClick,
  curLang
}) => {
  const draggableRef = useRef(null)

  const handleTitleChange = (text) => {
    logicObj.languages[curLang].title=text
    setDB([...DB])
  }

  const handleAddArea = () => {
    const textId1 = generateNewId(currentCompany.logic.map(n=>n.texts).flat()).toString()
    logicObj.texts.push(textId1)
    for(let lang of Object.keys(logicObj.languages)){
      logicObj.languages[lang].texts.push("")
    }

    setDB([...DB])
  }

  const handleDeleteTextArea = (index) => {
    const id = logicObj.texts[index]
    const connectedTitle=currentCompany.logic.find(n=>n.title.connection.includes(id))
    if(connectedTitle){
      connectedTitle.title.connection=connectedTitle.title.connection.filter(n=>n!==id)
    }

    for(let lang of Object.keys(logicObj.languages)){
      logicObj.languages[lang].texts.splice(index,1)
    }
    logicObj.texts.splice(index,1)

    setDB([...DB])
  }

  const handleChangeArea = (index, text) => {
    logicObj.languages[curLang].texts[index] = text
    setDB([...DB])
  }

  const handleStop = (e, data) => {
    if (logicObj.x !== data.x || logicObj.y !== data.y) {
      logicObj.x = data.x
      logicObj.y = data.y
      handleDrag()
    }
  }

  const setEndPoint = (e) => {
    if (e.target.value.length <= 1) {
      logicObj.endpoint = e.target.value
      setDB([...DB])
    }
  }

  const handleEndpointSelectChange=(e)=>{
    logicObj.endpoint=e.target.value;
    setDB([...DB])
  }
  return (
    <Draggable
      onStop={handleStop}
      defaultPosition={{ x: logicObj.x, y: logicObj.y }}
      nodeRef={draggableRef}>
      <div className={styles.container} ref={draggableRef}>
        <div className={styles.myNote}>
          <div className={styles.myNoteHeader}>
            <div className={styles.ballAndInput}>
              <div
                className={logicObj.id}
                style={
                  currentConnection?.id===logicObj.id
                    ? connectedStyle
                    : {}
                }
                id={styles.titleBall}
                onClick={() => handleTitleClick(logicObj)}
              />
{/*               <input
                style={{ width: '13px', fontSize: '10px', height: '15px' }}
                value={logicObj.endpoint}
                onChange={setEndPoint}
              /> */}
            <select value={logicObj.endpoint} onChange={handleEndpointSelectChange}>
              {allowedMarks.map(n=>{
                return <option value={n}>{n}</option>
              })}
            </select>
            </div>
            <AutoExpandingTextarea
              myValue={logicObj.languages[curLang].title}
              setValue={handleTitleChange}
            />
            <IoIosAddCircleOutline onClick={handleAddArea} style={{color:"white",cursor:"pointer",fontSize:"20px",marginLeft:"4px"}}/>
            <RiDeleteBin6Line onClick={()=>handleDeleteDragNote(logicObj.id)} style={{color:"white",cursor:"pointer",fontSize:"20px",marginLeft:"4px"}}/>
          </div>

          {logicObj.languages[curLang].texts.map((curText, i) => {
            const textId=logicObj.texts[i]
            return (
              <div className={styles.textAreaHolder} key={i}>
                <AutoExpandingTextarea
                  style={{borderRadius:"5px",padding:"2px"}}
                  myValue={curText}
                  setValue={(text)=>handleChangeArea(i,text)}
                />
                <RiDeleteBin6Line onClick={()=>handleDeleteTextArea(i)} style={{color:"white",cursor:"pointer",fontSize:"20px",marginTop:"10px",marginLeft:"5px"}}/>

                <div className={styles.ballContainer}>
                  <div
                    className={textId}
                    id={styles.ball}
                    style={
                      currentConnection?.id === textId ? connectedStyle : {}
                    }
                     onClick={() => handleTextClick(textId)}
                   />
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </Draggable>
  )
}
export default MyNote

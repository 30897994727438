import styles from './Context.module.css'
import React, { useEffect, useRef, useState } from 'react'



function getTimeInTimeZone(offset) {
  // Get the current time in UTC
  let utcTime = new Date();

  // Get the current UTC time in milliseconds
  let utcTimeInMillis = utcTime.getTime();

  // Convert the offset from hours to milliseconds
  let offsetInMillis = offset * 60 * 60 * 1000;

  // Calculate the time in the specified time zone
  let timeInTimeZoneInMillis = utcTimeInMillis + offsetInMillis;

  // Create a new Date object for the time in the specified time zone
  let timeInTimeZone = new Date(timeInTimeZoneInMillis);

  const day=timeInTimeZone.toDateString().split(" ")[0];
  const time=timeInTimeZone.toISOString().slice(0, 19).replace('T', ' ');

  return {day:day,time:time};
}


const Context = ({ currentCompany, DB, setDB, setDB_old, DB_old }) => {
  const [selectedContext, setSelectedContext] = useState(true)
  const textareaRef = useRef(null)

  const adjustHeight = () => {
    const textarea = textareaRef.current
    if (textarea) {
      textarea.style.height = 'auto'
      textarea.style.height = `${textarea.scrollHeight+10}px`
    }
  }

  useEffect(() => {
    adjustHeight()
  }, [currentCompany,selectedContext])

  const handleChange = (event) => {
    const field = selectedContext ? 'development' : 'production'
    const textarea = event.target
    currentCompany.contextObj[field] = textarea.value
    setDB([...DB])
    textarea.style.height = 'auto'
    textarea.style.height = textarea.scrollHeight+10 + 'px'
    adjustHeight()
  }

  let timeString=""
  if(currentCompany.contextObj.time){
    const timeObj=getTimeInTimeZone(Number(currentCompany.contextObj?.time?.time))
    timeString="This is the current date and location: "+currentCompany.contextObj.time.location+" "+timeObj.day+" "+ timeObj.time+" "+currentCompany.contextObj.time?.time?.toString()
  }


  function handleClick() {
    setSelectedContext((prev) => !prev)
  }

  const handleTimeChange=(e)=>{
    if(e.target.checked){
      currentCompany.contextObj.time={time:"0",location:""}
      setDB([...DB])
    }
    else{
      delete currentCompany.contextObj.time;
      setDB([...DB])

    }
  }
  //const options=["-1","0","1","2","3","4"]
  let options = Array.from({length: 41}, (v, k) => k - 20).map(n=>n.toString())

  const handleTimeValueChange=(e)=>{
    currentCompany.contextObj.time.time=e.target.value
    setDB([...DB])
  }


  const handleLocationChange=(e)=>{
    currentCompany.contextObj.time.location=e.target.value
    setDB([...DB])
  }

  const handlePush=()=>{
    currentCompany.contextObj.production=currentCompany.contextObj.development
    setDB([...DB])
  }


  if (currentCompany === '') {
    return <div style={{ height: '1000px' }}></div>
  } else {
    return (
      <div className={styles.container}>
        <div className={styles.ContextOptions}>
          <div
            style={
              selectedContext
                ? { backgroundColor: '#041f3e' }
                : {
                    background: 'white',
                    color: '#041f3e',
                    border: '1px solid #041f3e',
                  }
            }
            onClick={handleClick}>
            context
          </div>
          <div
            style={
              !selectedContext
                ? { backgroundColor: '#041f3e' }
                : {
                    background: 'white',
                    color: '#041f3e',
                    border: '1px solid #041f3e',
                  }
            }
            onClick={handleClick}>
            context production
          </div>
        </div>
            <div className={styles.pushButton} onClick={handlePush}>
              {"development -> production"}
            </div>
        <textarea
          className={styles.contextTextArea}
          value={
            selectedContext
              ? currentCompany.contextObj.development
              : currentCompany.contextObj.production
          }
          onChange={handleChange}
          style={{ overflow: 'hidden', resize: 'none' }}
          ref={textareaRef}
        />
        <div style={{margin:"8px 0px"}}>
          <input checked={currentCompany.contextObj.time} onChange={handleTimeChange} type="checkbox"/>
          <span>Add time </span>
        </div>
      {currentCompany.contextObj.time &&
        <div style={{display:"flex",flexDirection:"column",width:"50px",gap:"8px"}}>
          <select value={currentCompany.contextObj.time.time} onChange={handleTimeValueChange} >
              {options.map((n,i)=>{
                return <option key={i} value={n}>{n}</option>
              })}
          </select>

          <div style={{display:"flex",gap:"10px"}}>
            Location:
            <input value={currentCompany.contextObj.time.location} onChange={handleLocationChange}/>
          </div>

          <div style={{whiteSpace:"nowrap"}}>
            {timeString}
          </div>
        </div>
      }
      </div>
    )
  }
}
export default Context

import styles from './settings.module.css'
import { useNavigate } from 'react-router-dom'
import { deleteCompany } from '../../api'
import React from 'react'
import { IoIosAddCircleOutline } from 'react-icons/io'
import { RiDeleteBin6Line } from "react-icons/ri"



function Settings({
  DB,
  setDB,
  DB_old,
  setDB_old,
  currentCompany,
  setCompanyName,
  companyNames,
  setCompanyNames,

}) {
  const navigate = useNavigate()

  const handleEmailChange = (event) => {
    currentCompany.email = event.target.value
    setDB([...DB])
  }

  /*   const handleSave = async () => {
    try {
      for (var company of DB) {
        const staticCompany = DB_old.find(
          (company) => company._id === currentCompany._id
        )
        if (company.email !== staticCompany.email) {
          saveEmail({ email: company.email }, company._id)
          staticCompany.email = company.email
        }
      }
      setDB_old([...DB_old])
    } catch (e) {}
  }
 */
  async function handleCompanyDelete() {
    let isConfirmed = window.confirm(
      `Are you sure you want to delete ${currentCompany.name}?`
    )
    if (isConfirmed) {
      try {
        setCompanyName('-')
        navigate('/')
        await deleteCompany(currentCompany.name)
        companyNames = companyNames.filter(
          (n) => n.name !== currentCompany.name
        )

        DB = DB.filter((n) => n.name !== currentCompany.name)
        DB_old = DB_old.filter((n) => n.name !== currentCompany.name)
        setDB([...DB])
        setDB_old([...DB_old])
        setCompanyNames([...companyNames])
      } catch (e) {
        console.log(e)
      }
    }
  }

  const handleDemoChange = (field) => (e) => {
    currentCompany.demo[field] = e.target.value
    setDB([...DB])
  }

  /*   const handleDemoSave = async () => {
    await postDemo({ ...currentCompany.demo }, currentCompany._id)
    const currentStaticCompany = DB_old.find(
      (c) => c._id === currentCompany._id
    )
    currentStaticCompany.demo = JSON.parse(JSON.stringify(currentCompany.demo))
    setDB_old([...DB_old])
  } */

  const handleShopifyChange=(field)=>(e)=>{
    if(!currentCompany.shopify){
      currentCompany.shopify={companyName:"",apiKey:""}
    }
    currentCompany.shopify[field] = e.target.value
    setDB([...DB])
  }

  const handlePasswordChange=(e)=>{
    currentCompany.password=e.target.value;
    setDB([...DB])
  }

  const handleDemoIsDisplayed=(e)=>{
    currentCompany.demoIsDisplayed=e.target.checked;
    setDB([...DB])

  }
  const handleLogoUrlChange=(e)=>{
    currentCompany.logoUrl=e.target.value
    setDB([...DB])
  }

  const handleLangChange=(i)=>(e)=>{
    currentCompany.priorityLanguages[i]=e.target.value;
    setDB([...DB])
  }

  const handlePriorityLangAdd=()=>{
    currentCompany.priorityLanguages.unshift("")
    setDB([...DB])
  }
  const handlePriDelete=(i)=>{
    currentCompany.priorityLanguages=currentCompany.priorityLanguages.filter((_,index)=>index!==i)
    setDB([...DB])
  }
  let a ="aa";


  if (currentCompany === '') {
    return <div style={{ height: "1000px" }}></div>
  }
    return (
      <div className={styles.container}>
        <div className={styles.settingsTab}>
          <div className={styles.inputBox2}>
            <span>Set to demo page</span>
            <input type='checkbox' checked={currentCompany.demoIsDisplayed} onChange={handleDemoIsDisplayed}/>
          </div>
            <div className={styles.inputBox}>
                  <div>Logo Url</div>
                  <input value={currentCompany.logoUrl} onChange={handleLogoUrlChange}/>
            </div>
          
          <div className={styles.priorityLanguages}>
            <div className={styles.priorityBox}>
              <span>Add priority lanugages</span>
              <IoIosAddCircleOutline onClick={()=>handlePriorityLangAdd()}/>
            </div>
            {currentCompany.priorityLanguages.map((lang,i)=>{
              return <div className={styles.priObj} key={i}><input value={lang} onChange={handleLangChange(i)}/><RiDeleteBin6Line onClick={()=>handlePriDelete(i)}/></div>
            })}
          </div>

          <div className={styles.inputBox}>
            <span>Email:</span>
            <input value={currentCompany.email} onChange={handleEmailChange} />
          </div>
          <div className={styles.inputBox}>
            <span>Password:</span>
            <input value={currentCompany.password?currentCompany.password:""} onChange={handlePasswordChange} />
          </div>

          <div className={styles.inputBox}>
            <span>Shopify store name:</span>
            <input value={currentCompany.shopify?.companyName ? currentCompany.shopify.companyName:"" } onChange={handleShopifyChange("companyName")} />
          </div>

          <div className={styles.inputBox}>
            <span>Shopify api key:</span>
            <input value={currentCompany.shopify?.apiKey ? currentCompany.shopify.apiKey:"" } onChange={handleShopifyChange("apiKey")} />
          </div>

          <div className={styles.inputBox}>
            <span>ID:</span>
            <div>{currentCompany._id}</div>
          </div>

          <div>Demo styling:</div>
          <div className={styles.styling}>
            <div className={styles.column}>
              <div>Color</div>
              <div>Header color</div>
              <div>border</div>
            </div>
            <div className={styles.column}>
              <input
                value={currentCompany.demo?.color?currentCompany.demo.color:""}
                onChange={handleDemoChange('color')}
              />
              <input
                value={currentCompany.demo?.headerColor?currentCompany.demo.headerColor:""}
                onChange={handleDemoChange('headerColor')}
              />
              <input
                value={currentCompany.demo?.border?currentCompany.demo.border:""}
                onChange={handleDemoChange('border')}
              />
            </div>
          </div>

          <div>
            {`<script> id="emeric-script" data-param1="${currentCompany._id}" 
                            ${currentCompany.demo?.color && `color="` + currentCompany.demo.color + `"`}
                            ${currentCompany.demo?.headerColor && `header-color="` + currentCompany.demo.color + `"`}
                            ${currentCompany.demo?.border && `border="` + currentCompany.demo.border + `"`}
                            </script>`}
          </div>

          <button
            onClick={() => handleCompanyDelete()}
            className={styles.delete}>
            Delete company
          </button>
        </div>
      </div>
    )
  }


export default Settings

import styles from './logic.module.css'
import MyNote from './components/MyNote'
import React, { useState } from 'react'
import SteppedLineTo, { LineToProps } from 'react-lineto'
import { generateNewId } from 'functions/id'
import Form from './components/Form'
import { Company,logicObj,ConnectionObj } from 'types'

const connectedStyle = { backgroundColor: 'green' }
const deleteStyle = { backgroundColor: 'red' }

interface ILogic{
  DB:Array<Company>;
  setDB:(DB:Array<Company>)=>void;
  currentCompany:Company; 
}

interface aa{
  title:string;
  texts:Array<string>
}
interface langObject{
  [key: string]: aa;
}


const Logic:React.FC<ILogic>=({DB,setDB,currentCompany})=>{
  const currentLanguages=currentCompany.languages
  const [curLang, setCurLang] = useState(currentLanguages[0])
  const logic=currentCompany.logic

  const [isConnecting, setIsConnecting] = useState(false)
  const [currentConnection, setCurrentConnection] = useState<ConnectionObj|null>(null)
  const [isDeleting, setIsDeleting] = useState(false)

  const languages = ["english","finnish","swedish"]
  const [addLangHover,setAddLangHover]=useState(false)

  const languageObject = currentLanguages.reduce((obj:langObject, language) => {
    obj[language] = {title:"",texts:[]};
    return obj;
  }, {});

  const handleLanguageAdd=(lang:string)=>{
    currentCompany.logic.forEach(n=>{
      const newLangObj={title:"",texts:n.texts.map(n=>"")}
      n.languages[lang]=newLangObj
    })
    currentLanguages.push(lang)
    setAddLangHover(false)
    setDB([...DB])
  }


  async function handleDeleteDragNote(id:string) {
    const removeIndex=logic.findIndex(n=>n.id===id)
    const textIds=logic.find(n=>n.id===id)?.texts
    if(textIds){
      currentCompany.logic.forEach(n=>{
        if(n.type !== "form"){
          n.title.connection=n.title.connection.filter(n=>!textIds.includes(n))
        }
      })
      currentCompany.logic=currentCompany.logic.filter((_,i)=>i!==removeIndex)
      setDB([...DB])
    }

  }
  

  
  const handleAddNote = async () => {
    const id = generateNewId(currentCompany.logic.map(n=>n.id)).toString()

    const newLogicNote={x:0,y:0,title:{connection:[]},texts:[],endpoint:"",id:id,languages:JSON.parse(JSON.stringify(languageObject)) }
    logic.push(newLogicNote)
    setDB([...DB])
  }

  const handleAddForm=()=>{
    const id = generateNewId(logic.map(n=>n.id))
    const newLogicNote:logicObj={x:0,y:0,title:{connection:[]},texts:["input","text"],endpoint:"!",type:"form",id:id,languages:JSON.parse(JSON.stringify(languageObject))}
    logic.push(newLogicNote)

    setDB([...DB])
  }

  const handleLangDelete=()=>{
    currentCompany.logic.forEach(n=>{
      delete n.languages[curLang]
    })
    currentCompany.languages=currentLanguages.filter(n=>n!==curLang)
    setCurLang(currentLanguages[0])
    setDB([...DB])
  }


  const handleSetFirst = () => {
    if (curLang !== currentLanguages[0]) {
      const index=currentLanguages.indexOf(curLang)
      currentLanguages.splice(index, 1)
      currentLanguages.unshift(curLang);
      setDB([...DB])
    } 
  }

  const handleLangClick = (lang:string) => {
    setCurLang(lang)
  }


  const handleConnectionDelete = () => {
    if(isDeleting){
      setIsDeleting(false)
    }
    else{
      setIsConnecting(false)
      setCurrentConnection(null)
      setIsDeleting(true)
    }
  }

  const handleConnectClick = () => {
    if(isConnecting){
      setIsConnecting(false)
      setCurrentConnection(null)
    }
    else{
      setIsDeleting(false)
      setIsConnecting(true)
    }

  }

  const handleDrag = () => {
    setDB([...DB])
  }

  const handleTitleClick=(logicObj:logicObj)=>{
    const currentTitle= logicObj.title;

    if(isConnecting){
      if(!currentConnection){
        setCurrentConnection({type:"title",id:logicObj.id})
      }
      else if(currentConnection.id===logicObj.id){
        setCurrentConnection(null)
      }
      else if(currentConnection.type==="title"){
        return;
      }
      else{
          if(!logicObj.texts.includes(currentConnection.id)){
            currentTitle.connection.push(currentConnection.id)
            setCurrentConnection(null)
          }
        
      }
    }
    else if(isDeleting){
      currentTitle.connection=[]
    }
    setDB([...DB])
  }

  const handleTextClick=(id:string)=>{
    if(isConnecting){
      const alreadyConnected=logic.find(n=>n.title.connection.includes(id))
      if(alreadyConnected){
        return
      }
      
      if(!currentConnection){
        setCurrentConnection({type:"text",id:id})
      }
      else if(currentConnection.id===id){
        setCurrentConnection(null)
      }
      else if(currentConnection.type==="text"){
        setCurrentConnection({type:"text",id:id})
      }
      else{
        const currentNote = logic.find(n => n.texts.includes(id))
        if(currentNote && currentNote.id !== currentConnection.id){
          const connectionNote=logic.find(n=>n.id===currentConnection.id)
          if(connectionNote){
            connectionNote.title.connection.push(id)
            setCurrentConnection(null)
          }

        }
      }
    }
    else if(isDeleting){
      const connectedTitle= logic.find(n=>n.title.connection.includes(id));
      if(connectedTitle){
        connectedTitle.title.connection=connectedTitle.title.connection.filter(n=>n!==id)
      }

    }
    setDB([...DB])
  }

  const handleFirstBallClick=()=>{
    const id="0"
    if(isConnecting){
      const alreadyConnected=logic.find(n=>n.title.connection.includes(id))
      if(alreadyConnected){
        return
      }
      
      if(!currentConnection){
        setCurrentConnection({type:"text",id:id})
      }
      else if(currentConnection.id===id){
        setCurrentConnection(null)
      }
      else if(currentConnection.type==="text"){
        setCurrentConnection({type:"text",id:id})
      }
      else{
        const connectionNote=logic.find(n=>n.id===currentConnection.id)
        if(connectionNote){
          connectionNote.title.connection.push(id)
          setCurrentConnection(null)
        }

      }
    }
    else if(isDeleting){
      const connectedTitle= logic.find(n=>n.title.connection.includes(id));
      if(connectedTitle){
        connectedTitle.title.connection=connectedTitle.title.connection.filter(n=>n!==id)
      }

    }
    setDB([...DB])
  }





  if (!currentCompany) {
    return <div style={{ height: '1000px' }}></div>
  } 
    return (
      <div className={styles.container}>
        <div className={styles.langHolder}>
          {currentLanguages.map((n, i) => (
            <div
              key={i}
              onClick={() => handleLangClick(n)}
              className={styles.languageButton}
              style={
                curLang === n
                  ? { backgroundColor: '#041f3e', color: 'white' }
                  : { backgroundColor: 'white', color: '#041f3e' }
              }>
              <span>{n}</span>
            </div>
          ))}
          <div onMouseEnter={()=>setAddLangHover(true)} onMouseLeave={()=>setAddLangHover(false)}
            style={{position:"relative",border:"1px solid black",display:"flex",
              alignItems:"center",justifyContent:"center",padding:"7px",
              borderRadius:"7px"
            }}
            >

            <div>Add language</div>
            {addLangHover && 
              <div className={styles.addLanguageMenu}>
                {languages.filter(lang=>!currentLanguages.includes(lang)   ).map((n,i)=>{
                  return <div key={i} onClick={()=>handleLanguageAdd(n)}>{n}</div>
                })}
              </div>
            }
          </div>

        </div>
        <div style={{display:"flex",alignItems:"center"}}>
          <div onClick={()=>handleLangDelete()} style={{cursor:"pointer",margin:"10px",border:"1px solid black",
            "padding":"8px",borderRadius:"8px"
          }}>
            Delete language  
          </div>
        </div>
          <div style={{margin:"5px 0 0 10px"}}>
          <button onClick={handleSetFirst}>set first</button>
            <button onClick={handleAddNote}>add</button>
            <button
              onClick={handleConnectClick}
              style={isConnecting ? connectedStyle : {}}>
              connect
            </button>
            <button
              onClick={handleConnectionDelete}
              style={isDeleting ? deleteStyle : {}}>
              delete connection
            </button>
            <button onClick={handleAddForm}>
              Add form
            </button>
          </div>


        {curLang && (
          <div className={styles.innerBox} id='inner' onScroll={handleDrag}>


            <div className={styles.myNotesBox}>
              <div className={styles.startHolder}>
                <div className={styles.start}>start</div>
                <div
                  id={styles.ball}
                  className='0'
                   onClick={() => handleFirstBallClick()}
                   style={currentConnection?.id==='0' ? connectedStyle : {}}
                />
              </div>

              {currentCompany.logic.map((logicObj, i) => {
                if(logicObj.type==="form"){
                  return (
                    <Form
                    currentConnection={currentConnection}
                    currentCompany={currentCompany}
                    key={logicObj.id}
                    handleDeleteDragNote={handleDeleteDragNote}
                    handleDrag={handleDrag}
                    setDB={setDB}
                    DB={DB}
                    logicObj={logicObj}
                    handleTitleClick={handleTitleClick}
                    curLang={curLang}
                    />
                  )
                }
                else{
                  return (
                    <MyNote
                      currentConnection={currentConnection}
                      currentCompany={currentCompany}
                      key={logicObj.id}
                      handleDeleteDragNote={handleDeleteDragNote}
                      handleDrag={handleDrag}
                      setDB={setDB}
                      DB={DB}
                      logicObj={logicObj}
                      handleTitleClick={handleTitleClick}
                      handleTextClick={handleTextClick}
                      curLang={curLang}
                    />
                  )
                }


              })}

              {currentCompany.logic
                .filter((n) => n.title.connection.length > 0)
                .map((n, index) => {
                  return n.title.connection.map((connection, connectionIndex) => {
                    const searchBarProps:LineToProps = { // make sure all required component's inputs/Props keys&types match
                      from:connection,
                      to:n.id,

                    }

                    return (
                      <SteppedLineTo
                        {...searchBarProps}
                        key={`${index}-${connectionIndex}`}
                        borderColor='black'

                      />
                    );
                  });
                })}

            </div>
          </div>
        )}
      </div>
    )
  
}

export default Logic
